// DriverShop.js

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Shop.css'; // Reuse the same styling

const DriverShop = () => {
  const location = useLocation();
  const driverEmail = location.state?.email || localStorage.getItem('email'); // Retrieve driver's email

  const [bioData, setBioData] = useState(null);
  const [conversionRate, setConversionRate] = useState('');
  const [catalogItems, setCatalogItems] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [viewCart, setViewCart] = useState(false);
  const [subtotal, setSubtotal] = useState(0);
  const [pointsSubtotal, setPointsSubtotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const catalogUrl = 'https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/Shop/Catalog';
  const cartUrl = 'https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/Shop/Cart';
  const orderUrl = 'https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/orders';
  const ebayApiUrl = 'https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/Shop';

  useEffect(() => {
    const fetchCartData = async () => {
      try {
        const email = location.state?.email || localStorage.getItem('email');

        if (email) {
          localStorage.setItem('email', email);
          // Proceed with fetching data using the email
        } else {
          setError('Email is undefined');
          setLoading(false);
        }

        // Fetch user bio data
        const bioResponse = await fetch(
          `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/bio?email=${encodeURIComponent(email)}`
        );
        if (!bioResponse.ok) {
          const errorBody = await bioResponse.text();
          throw new Error(`Failed to fetch bio data: ${errorBody}`);
        }
        const bioData = await bioResponse.json();
        setBioData(bioData);

        // Fetch conversion rate for sponsorOrg
        const conversionRateResponse = await fetch(
          `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/pointConversion?sponsorOrg=${encodeURIComponent(
            bioData.sponsorOrg
          )}`
        );
        if (!conversionRateResponse.ok) {
          console.warn('No conversion rate available for this sponsorOrg.');
          setConversionRate(null);
        } else {
          const conversionRateData = await conversionRateResponse.json();
          setConversionRate(conversionRateData.conversionRate);
        }

      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    fetchCartData();
  }, []);

  // Fetch driver's sponsor's catalog items
  const fetchCatalog = async () => {
    setLoading(true);
    setViewCart(false); // Switch to catalog view
    const url = `${catalogUrl}?sponsorEmail=${encodeURIComponent(driverEmail)}`;

    try {
      const response = await fetch(url, { method: 'GET' });
      if (!response.ok) {
        throw new Error('Failed to fetch catalog');
      }
      const catalogData = await response.json();
      setCatalogItems(catalogData);
    } catch (error) {
      setError(error.message);
      console.error('Error fetching catalog:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch current price and availability for an item
  const getCurrentPrice = async (itemTitle) => {
    const keyword = encodeURIComponent(itemTitle);
    const url = `${ebayApiUrl}?keyword=${keyword}&resultsPerPage=1`;

    try {
      const response = await fetch(url, { method: 'GET' });
      if (!response.ok) {
        throw new Error('Failed to fetch current price');
      }
      const data = await response.json();
      // Parse the response to get current price and availability
      const items = data.findItemsAdvancedResponse[0].searchResult[0].item;
      if (items && items.length > 0) {
        const currentItem = items[0];
        const currentPrice = currentItem.sellingStatus[0].currentPrice[0].__value__;
        const availability = currentItem.sellingStatus[0].sellingState[0];
        return { currentPrice, availability };
      } else {
        return { currentPrice: null, availability: 'Not Available' };
      }
    } catch (error) {
      console.error('Error fetching current price:', error);
      return { currentPrice: null, availability: 'Error' };
    }
  };

  // Fetch driver's cart items
  const fetchCartItems = async () => {
    setLoading(true);
    setViewCart(true); // Switch to cart view
    const url = `${cartUrl}?driverEmail=${encodeURIComponent(driverEmail)}`;

    try {
      const response = await fetch(url, { method: 'GET' });
      if (!response.ok) {
        throw new Error('Failed to fetch cart items');
      }
      const cartData = await response.json();

      // Fetch current price and availability for each item
      const updatedCartItems = await Promise.all(cartData.map(async (item) => {
        const { currentPrice, availability } = await getCurrentPrice(item.customTitle || item.itemTitle);
        return { ...item, currentPrice, availability };
      }));

      setCartItems(updatedCartItems);

      // Calculate subtotal after cart items are updated
      const calculatedSubtotal = updatedCartItems.reduce((total, item) => total + parseFloat(item.currentPrice || item.itemPrice), 0);
      setSubtotal(calculatedSubtotal);

      // Calculate subtotal in points if conversionRate is available
      const pointsSubtotal = conversionRate ? Math.round(calculatedSubtotal / parseFloat(conversionRate)) : 0;
      setPointsSubtotal(pointsSubtotal);
    } catch (error) {
      setError(error.message);
      console.error('Error fetching cart items:', error);
    } finally {
      setLoading(false);
    }
  };

  // Add item to cart
  const addItemToCart = async (item) => {
    console.log('Item being added to cart:', item); // Add this line
    const payload = {
      action: "add",
      driverEmail: driverEmail,
      itemTitle: item.itemTitle,
      customTitle: item.customTitle || '', // Include customTitle if available
      itemUrl: item.itemUrl,
      itemImageUrl: item.itemImageUrl,
      itemPrice: item.itemPrice.toString(),
      currency: item.currency
    };

    try {
      const response = await fetch(cartUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        alert('Item added to cart successfully!');
        await fetchCartItems();
      } else {
        const errorData = await response.text();
        alert(`Failed to add item to cart: ${errorData}`);
      }
    } catch (error) {
      console.error('Error adding item to cart:', error);
      alert('An error occurred while adding the item to the cart.');
    }
  };

  // Remove item from cart
  const removeItemFromCart = async (item) => {
    const payload = {
      driverEmail: driverEmail,
      itemTitle: item.itemTitle
    };

    try {
      const response = await fetch(cartUrl, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        alert('Item removed from cart successfully!');
        await fetchCartItems();
      } else {
        const errorData = await response.text();
        alert(`Failed to remove item from cart: ${errorData}`);
      }
    } catch (error) {
      console.error('Error removing item from cart:', error);
      alert('An error occurred while removing the item from the cart.');
    }
  };

  useEffect(() => {
    fetchCatalog(); // Fetch catalog on component mount
  }, []);

  const placeOrder = async () => {
    if (!bioData) return alert('User data not loaded');

    const orderDetails = {
      userID: bioData.userID,
      sponsorOrgID: bioData.sponsorOrgID,
      orderContents: cartItems.map(item => item.customTitle || item.itemTitle).join(', '),
      pointCost: Math.round(pointsSubtotal),
      currencyCost: parseFloat(subtotal.toFixed(2)),
      timePlaced: new Date().toISOString()
    };

    console.log(orderDetails);

    try {
      const response = await fetch(orderUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(orderDetails)
      });

      if (response.ok) {
        alert('Order placed successfully!');

        // Clear the cart by removing each item
        for (const item of cartItems) {
          await removeItemFromCart(item);
        }
        setCartItems([]);

      } else {
        const errorData = await response.text();
        alert(`Failed to place order: ${errorData}`);
      }
    } catch (error) {
      alert('An error occurred while placing the order');
    }
  };

  return (
    <div className="shop-container">
      <h1>My Sponsor's Catalog</h1>
      <div>
        {viewCart ? (
          <button className="catalog-button" onClick={fetchCatalog}>View Catalog</button>
        ) : (
          <button className="cart-button" onClick={fetchCartItems}>View Cart</button>
        )}
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <>
          {!viewCart && (
            <div className="item-grid">
              {catalogItems.length > 0 ? (
                catalogItems.map((item, index) => (
                  <div key={index} className="item-card">
                    <h3>{item.customTitle || item.itemTitle}</h3>
                    {item.itemImageUrl && (
                      <img src={item.itemImageUrl} alt={item.customTitle || item.itemTitle} />
                    )}
                    {conversionRate ? (
                      <p>
                        Price: {Math.round(parseFloat(item.itemPrice) / conversionRate)} Points
                      </p>
                    ) : (
                      <p>
                        Price: {item.itemPrice} {item.currency}
                      </p>
                    )}
                    <a
                      href={item.itemUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="view-item-button"
                    >
                      View Item
                    </a>
                    <button className='add-cart-button' onClick={() => addItemToCart(item)}>Add to Cart</button>
                  </div>
                ))
              ) : (
                <p>No items in the sponsor's catalog.</p>
              )}
            </div>
          )}
          {viewCart && (
            <div>
              <h2>My Cart</h2>
              <div className="item-grid">
                {cartItems.length > 0 ? (
                  cartItems.map((item, index) => (
                    <div key={index} className="item-card">
                      <h3>{item.customTitle || item.itemTitle}</h3>
                      {item.itemImageUrl && (
                        <img src={item.itemImageUrl} alt={item.customTitle || item.itemTitle} />
                      )}
                      {conversionRate ? (
                        <p>
                          Price: {Math.round(parseFloat(item.currentPrice || item.itemPrice) / conversionRate)} Points
                        </p>
                      ) : (
                        <p>
                          Price: {item.currentPrice || item.itemPrice} {item.currency}
                        </p>
                      )}
                      <p>
                        Availability: {item.availability || 'Unknown'}
                      </p>
                      <button className='remove-cart-button' onClick={() => removeItemFromCart(item)}>Remove from Cart</button>
                    </div>
                  ))
                ) : (
                  <p>Your cart is empty.</p>
                )}
              </div>
              {/* Display point subtotal only */}
              {cartItems.length > 0 && (
                <div className="subtotal">
                  {conversionRate && (
                    <h3>Point Subtotal: {pointsSubtotal} pts</h3>
                  )}
                  <button className="order-button" onClick={placeOrder}>Place Order</button>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DriverShop;
