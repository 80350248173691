import React, { useState, useEffect } from 'react';
import './SponsorManagement.css';

const SponsorManagement = () => {
  const [sponsorOrgs, setSponsorOrgs] = useState([]);
  const [newSponsorName, setNewSponsorName] = useState('');
  const [selectedSponsor, setSelectedSponsor] = useState('');
  const [conversionRate, setConversionRate] = useState(null);
  const [editedConversionRate, setEditedConversionRate] = useState({ conversionRate: 0 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchSponsorOrgs();
  }, []);

  const fetchSponsorOrgs = async () => {
    try {
      const response = await fetch(
        'https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/sponsors'
      );
      if (!response.ok) {
        throw new Error('Failed to fetch sponsor organizations');
      }
      const data = await response.json();
      setSponsorOrgs(data);
    } catch (err) {
      setError('Failed to load sponsor organizations');
      console.error('Error fetching sponsors:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateSponsorOrg = async () => {
    if (!newSponsorName.trim()) {
      alert('Please enter a sponsor organization name');
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(
        'https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/createSponsorOrg',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            sponsorOrgName: newSponsorName.trim(),
          }),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to create sponsor organization');
      }

      alert('Sponsor organization created successfully!');
      setNewSponsorName('');
      // Refresh the sponsor organizations list
      await fetchSponsorOrgs();
    } catch (error) {
      console.error('Error creating sponsor organization:', error);
      alert('Failed to create sponsor organization. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSponsorSelect = async (event) => {
    const selectedSponsorOrg = event.target.value;
    setSelectedSponsor(selectedSponsorOrg);

    if (selectedSponsorOrg) {
      try {
        const response = await fetch(
          `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/pointConversion?sponsorOrg=${encodeURIComponent(
            selectedSponsorOrg
          )}`
        );
        
        if (!response.ok) {
          console.warn('No conversion rate available for this sponsorOrg.');
          setConversionRate(null);
          setEditedConversionRate({ conversionRate: 0 });
        } else {
          const conversionRateData = await response.json();
          setConversionRate(conversionRateData);
          setEditedConversionRate(conversionRateData);
        }
      } catch (error) {
        console.error('Error fetching conversion rate:', error);
        setConversionRate(null);
      }
    } else {
      setConversionRate(null);
      setEditedConversionRate({ conversionRate: 0 });
    }
  };

  const handleConversionRateChange = (event) => {
    const newRate = parseFloat(event.target.value);
    if (!isNaN(newRate)) {
      setEditedConversionRate({ conversionRate: newRate });
    }
  };

  const handleConversionRateSubmit = async () => {
    if (!selectedSponsor) {
      alert('Please select a sponsor organization');
      return;
    }

    try {
      const formattedRate = parseFloat(editedConversionRate.conversionRate).toFixed(2);
      
      const response = await fetch(
        'https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/pointConversion',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            sponsorOrg: selectedSponsor,
            conversionRate: formattedRate,
          }),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to update conversion rate');
      }

      setConversionRate(editedConversionRate);
      alert('Conversion rate updated successfully!');
    } catch (error) {
      console.error('Error updating conversion rate:', error);
      alert('Failed to update conversion rate. Please try again.');
    }
  };

  if (loading && sponsorOrgs.length === 0) {
    return <p className="loading">Loading...</p>;
  }

  return (
    <div className="sponsor-management-container">
      <h1>Sponsor Organization Management</h1>
      
      {error && <p className="error-message">{error}</p>}

      <div className="create-sponsor-section">
        <h2>Create New Sponsor Organization</h2>
        <div className="form-group">
          <input
            type="text"
            value={newSponsorName}
            onChange={(e) => setNewSponsorName(e.target.value)}
            placeholder="Enter sponsor organization name"
            className="form-control"
          />
          <button onClick={handleCreateSponsorOrg} className="submit-button" disabled={loading}>
            Create Sponsor Organization
          </button>
        </div>
      </div>

      <div className="manage-conversion-rates">
        <h2>Manage Conversion Rates</h2>
        <div className="form-group">
          <label htmlFor="sponsorSelect">Select Sponsor Organization:</label>
          <select
            id="sponsorSelect"
            value={selectedSponsor}
            onChange={handleSponsorSelect}
            className="form-control"
          >
            <option value="">-- Select Sponsor Organization --</option>
            {sponsorOrgs.map((org) => (
              <option key={org.sponsorOrgID} value={org.sponsorOrgName}>
                {org.sponsorOrgName}
              </option>
            ))}
          </select>
        </div>

        {selectedSponsor && (
          <div className="conversion-rate-section">
            <div className="current-rate">
              <p>
                <span className="bold-text">Current Conversion Rate: </span>
                {conversionRate ? (
                  `$${parseFloat(conversionRate.conversionRate).toFixed(2)}`
                ) : (
                  'No conversion rate set'
                )}
              </p>
            </div>

            <div className="form-group">
              <label htmlFor="newRate">New Conversion Rate ($):</label>
              <input
                type="number"
                id="newRate"
                value={editedConversionRate.conversionRate || ''}
                onChange={handleConversionRateChange}
                className="form-control"
                step="0.01"
                min="0"
              />
            </div>

            <button onClick={handleConversionRateSubmit} className="submit-button">
              Update Conversion Rate
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SponsorManagement;