import React, { useState, useEffect } from 'react';
import './Connect.css';

const Connect = () => {
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [selectedDriverBioData, setSelectedDriverBioData] = useState(null);

  useEffect(() => {
    // Fetch list of Drivers
    const fetchDrivers = async () => {
      try {
        const response = await fetch(
          'https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/users?role=Driver'
        );
        const data = await response.json();
        setDrivers(data);
      } catch (error) {
        console.error('Error fetching drivers:', error);
      }
    };

    fetchDrivers();
  }, []);

  const handleDriverSelect = async (email) => {
    setSelectedDriver(email);
    if (email) {
      // Fetch selected Driver's bio data
      try {
        const bioResponse = await fetch(
          `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/bio?email=${encodeURIComponent(email)}`
        );
        const bioData = await bioResponse.json();
        setSelectedDriverBioData(bioData);
      } catch (error) {
        console.error('Error fetching selected driver bio:', error);
      }
    } else {
      setSelectedDriverBioData(null);
    }
  };

  return (
    <div className="connect-container">
      <h1>Connect with Other Drivers</h1>

      <div className="driver-select">
        <label htmlFor="driverDropdown">Select a Driver:</label>
        <select
          id="driverDropdown"
          value={selectedDriver || ''}
          onChange={(e) => handleDriverSelect(e.target.value)}
        >
          <option value="">-- Select Driver --</option>
          {drivers.map((driver) => (
            <option key={driver.email} value={driver.email}>
              {driver.firstName} {driver.lastName} ({driver.email})
            </option>
          ))}
        </select>
      </div>

      {/* Display Driver's bio information */}
      {selectedDriverBioData && (
        <div className="bio-info">
          {/* Display profile picture */}
          {selectedDriverBioData.profilePicture && (
            <img
              src={`data:image/png;base64,${selectedDriverBioData.profilePicture}`}
              alt="Profile"
              className="profile-picture-top"
            />
          )}
          <h3>Email: {selectedDriverBioData.email}</h3>
          <h3>First Name: {selectedDriverBioData.firstName}</h3>
          <h3>Last Name: {selectedDriverBioData.lastName}</h3>
          <h3>Sponsor Org: {selectedDriverBioData.sponsorOrg}</h3>
          <h3>Biography: {selectedDriverBioData.biography}</h3>
          <h3>Hometown: {selectedDriverBioData.hometown}</h3>
          <h3>Favorite Landmark: {selectedDriverBioData.favoriteLandmark}</h3>
          {selectedDriverBioData.isBirthdayVisible && <h3>Birthday: {selectedDriverBioData.birthday}</h3>}
        </div>
      )}
    </div>
  );
};

export default Connect;
