import React from 'react';
import { useNavigate } from 'react-router-dom';
import background from '../../pictures/Home_Page_image2.jpg'; // Import your image
import './Home.css';

const Home = () => {
  const navigate = useNavigate();

  return (
    <div className="home-container" style={{ backgroundImage: `url(${background})` }}>
      <h1 className="home-title">Welcome to DriveRewards</h1>
      <p className="home-subtitle">
        Earn points for safe driving and redeem them in our exclusive store!
      </p>
      <div className="home-button-container">
        <button onClick={() => navigate('/login')} className="home-button">
          Login
        </button>
        <button onClick={() => navigate('/signup')} className="home-button">
          Sign Up
        </button>
      </div>
    </div>
  );
};

export default Home;
