// About.js
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './About.css'; // Import the external CSS

const About = () => {
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [productInfo, setProductInfo] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [editedInfo, setEditedInfo] = useState({});
  const location = useLocation(); // Get location to access passed email

  const email = location.state?.email || localStorage.getItem('email');

  if (email) {
    localStorage.setItem('email', email);
    // Proceed with fetching data using the email
  } else {
    setError('Email is undefined');
    setLoading(false);
  }

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await fetch(
          'https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/about'
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();

        const formattedReleaseDate = formatDateForDisplay(data['Release Date']);

        setProductInfo({
          teamNumber: data['Team Number'],
          versionNumber: data['Version Number'],
          releaseDate: formattedReleaseDate,
          productName: data['Product Name'],
          productDescription: data['Product Description'],
        });

        setEditedInfo({
          teamNumber: data['Team Number'],
          versionNumber: data['Version Number'],
          releaseDate: formattedReleaseDate,
          productName: data['Product Name'],
          productDescription: data['Product Description'],
        });
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    };

    const fetchUserDetails = async () => {
      try {
        const response = await fetch(
          `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/bio?email=${encodeURIComponent(email)}`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch user details');
        }
        const userData = await response.json();
        setUserRole(userData.userRole);
      } catch (error) {
        console.error('Error fetching user details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductData();
    fetchUserDetails();
  }, [email]);

  const formatDateForDisplay = (dateString) => {
    const date = new Date(dateString);

    // Adjust for timezone offset to avoid issues with local time
    const utcDate = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate()
    );

    const options = { month: '2-digit', day: '2-digit', year: '2-digit' };
    return utcDate.toLocaleDateString('en-US', options);
  };

  const formatDateForDatabase = (dateString) => {
    const [month, day, year] = dateString.split('/');
    const formattedDate = new Date(`20${year}-${month}-${day}`); // Assuming year is in YY format
    return formattedDate.toISOString();
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const handleEditSubmit = async () => {
    try {
      const formattedReleaseDate = formatDateForDatabase(
        editedInfo.releaseDate
      ); // Convert for database

      const requestBody = {
        ...editedInfo,
        releaseDate: formattedReleaseDate, // Use the formatted date for the request
      };

      const response = await fetch(
        'https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/about',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to update product information');
      }

      // Update productInfo with the editedInfo after successful update
      const updatedReleaseDate = formatDateForDisplay(formattedReleaseDate); // Convert back for display
      setProductInfo({ ...editedInfo, releaseDate: updatedReleaseDate });
      setIsEditing(false); // Exit edit mode after submission
    } catch (error) {
      console.error('Error submitting edited information:', error);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="about-content">
      <h1>About Page</h1>
      <section className="product-info">
        <h3>Product Information</h3>
        <ul>
          <li>
            <strong>Team #: </strong>
            <span>{productInfo.teamNumber}</span>
          </li>
          <li>
            <strong>Version #: </strong>
            <span>
              {isEditing ? (
                <input
                  type="text"
                  name="versionNumber"
                  value={editedInfo.versionNumber}
                  onChange={handleEditChange}
                />
              ) : (
                productInfo.versionNumber
              )}
            </span>
          </li>
          <li>
            <strong>Release Date: </strong>
            <span>
              {isEditing ? (
                <input
                  type="text"
                  name="releaseDate"
                  value={editedInfo.releaseDate}
                  onChange={handleEditChange}
                  placeholder="MM/DD/YY"
                />
              ) : (
                productInfo.releaseDate
              )}
            </span>
          </li>
          <li>
            <strong>Product Name: </strong>
            <span>
              {isEditing ? (
                <input
                  type="text"
                  name="productName"
                  value={editedInfo.productName}
                  onChange={handleEditChange}
                />
              ) : (
                productInfo.productName
              )}
            </span>
          </li>
          <li>
            <strong>Product Description: </strong>
            <span>
              {isEditing ? (
                <input
                  type="text"
                  name="productDescription"
                  value={editedInfo.productDescription}
                  onChange={handleEditChange}
                />
              ) : (
                productInfo.productDescription
              )}
            </span>
          </li>
        </ul>
        {userRole === 'Admin' && !isEditing && (
          <button onClick={() => setIsEditing(true)} className="edit-button">
            Edit
          </button>
        )}
        {isEditing && (
          <div>
            <button onClick={handleEditSubmit} className="save-button">
              Save
            </button>
            <button
              onClick={() => setIsEditing(false)}
              className="cancel-button"
            >
              Cancel
            </button>
          </div>
        )}
      </section>
    </div>
  );
};

export default About;
