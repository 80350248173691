import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signIn } from 'aws-amplify/auth'; // Importing the signIn method directly
import './Login.css'; // Import the external CSS file

async function checkAccountStatus(email) {
  const apiUrl = "https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/bio";

  try {
    const response = await fetch(`https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/bio?email=${encodeURIComponent(email)}`);

    if (response.ok) {
      const userData = await response.json();
      return userData.isActive === 1; // Return true if the account is active, false otherwise
    } else {
      return false; // Assume the account is inactive if there's an error fetching the data
    }
  } catch (error) {
    console.error('Error checking account status:', error);
    return false;
  }
}

async function logEmailStatus(email, success) {
  const apiUrl = "https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/loginLogging";

  // Data to send in the request body
  const requestData = {
      httpMethod: "POST",
      email: email,
      success: success
  };

  try {
      // Making a POST request to API Gateway
      const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestData)
      });

      // Checking if the request was successful
      if (response.ok) {
          const responseData = await response.json();
          console.log('Success:', responseData);
      } else {
          console.error('Error: Request failed with status', response.status);
      }
  } catch (error) {
      console.error('Error: Could not connect to the API Gateway', error);
  }
}

// Usage

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null); // To handle errors
  const [loading, setLoading] = useState(false); // To handle loading state
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate(); // Use useNavigate for redirection

  useEffect(() => {
    // Check localStorage for saved credentials
    const savedEmail = localStorage.getItem('savedEmail');
    const savedPassword = localStorage.getItem('savedPassword');
    if (savedEmail && savedPassword) {
      setEmail(savedEmail);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
  
    try {
      const isActive = await checkAccountStatus(email);
  
      if (!isActive) {
        setError('Your account has been deactivated. Please contact support.');
        setLoading(false);
        return;
      }
  
      // Proceed with sign-in using Amplify
      const { nextStep } = await signIn({
        username: email,
        password: password,
      });
  
      if (nextStep.signInStep === 'CONFIRM_SIGN_UP') {
        navigate('/confirm-signup', { state: { email: email } });
      } else {
        localStorage.setItem('email', email);
        navigate('/dashboard', { state: { email: email } });
        logEmailStatus(email, true);
      }
  
      if (rememberMe) {
        // Save credentials to localStorage if "Remember Me" is checked
        localStorage.setItem('savedEmail', email);
        localStorage.setItem('savedPassword', password);
      } else {
        localStorage.removeItem('savedEmail');
        localStorage.removeItem('savedPassword');
      }
    } catch (err) {
      if (err.message === 'There is already a signed in user.') {
        navigate('/dashboard', { state: { email: email } });
      } else {
        logEmailStatus(email, false);
        console.error('Error during sign-in:', err);
        setError(err.message || 'Failed to sign in. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-form-container">
        <h2 className="login-title">Login to DriveRewards</h2>
        <form onSubmit={handleLogin} className="login-form">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              className="form-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Enter your email"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              className="form-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Enter your password"
            />
          </div>
          <div className="form-group remember-me">
            <input
              type="checkbox"
              id="rememberMe"
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
            />
            <label htmlFor="rememberMe">Remember Me</label>
          </div>
          {error && <p className="error-message">{error}</p>} {/* Display error message */}
          <button type="submit" className="login-button" disabled={loading}>
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </form>
        <div className="login-footer">
          <button
            onClick={() => navigate('/forgot-password')}
            className="footer-button"
          >
            Forgot Password?
          </button>
          <button
            onClick={() => navigate('/signup')}
            className="footer-button"
          >
            Don’t have an account? Sign up
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
