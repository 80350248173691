import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Shop.css';

const AdminShop = () => {
  const location = useLocation();
  const adminEmail = location.state?.email || localStorage.getItem('email'); // Retrieve admin email

  const [items, setItems] = useState([]);
  const [catalogItems, setCatalogItems] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [selectedSponsorOrg, setSelectedSponsorOrg] = useState('');
  const [selectedSponsorEmail, setSelectedSponsorEmail] = useState('');
  const [conversionRate, setConversionRate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [keyword, setKeyword] = useState('');
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [viewCatalog, setViewCatalog] = useState(false);

  // New state variables
  const [editingTitles, setEditingTitles] = useState({});
  const [customTitles, setCustomTitles] = useState({});

  const addItemUrl =
    'https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/Shop/Catalog'; // Replace with your actual Lambda endpoint

  // Fetch all sponsors on component mount
  useEffect(() => {
    const fetchSponsors = async () => {
      try {
        const response = await fetch(
          'https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/sponsors/getSponsors'
        );
        if (!response.ok) {
          throw new Error('Failed to fetch sponsors');
        }
        const sponsorsData = await response.json();

        // Deduplicate sponsors based on sponsorOrgName
        const uniqueSponsors = sponsorsData.reduce((acc, sponsor) => {
          if (!acc.find((s) => s.sponsorOrgName === sponsor.sponsorOrgName)) {
            acc.push(sponsor);
          }
          return acc;
        }, []);

        setSponsors(uniqueSponsors);
      } catch (error) {
        console.error('Error fetching sponsors:', error);
      }
    };

    fetchSponsors();
  }, []);

  // Update selected sponsor email whenever the selected sponsor organization changes
  useEffect(() => {
    const sponsor = sponsors.find((s) => s.sponsorOrgName === selectedSponsorOrg);
    setSelectedSponsorEmail(sponsor?.email || '');
  }, [selectedSponsorOrg, sponsors]);

  // Fetch conversion rate when a sponsor organization is selected
  useEffect(() => {
    if (selectedSponsorOrg) {
      const fetchConversionRate = async () => {
        try {
          const conversionRateResponse = await fetch(
            `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/pointConversion?sponsorOrg=${encodeURIComponent(
              selectedSponsorOrg
            )}`
          );
          if (!conversionRateResponse.ok) {
            console.warn('No conversion rate available for this sponsorOrg.');
            setConversionRate(null);
          } else {
            const conversionRateData = await conversionRateResponse.json();
            setConversionRate(conversionRateData.conversionRate);
          }
        } catch (error) {
          console.error('Error fetching conversion rate:', error);
        }
      };

      fetchConversionRate();
    }
  }, [selectedSponsorOrg]);

  // Function to fetch items from the external API
  const fetchItems = async (pageNum, keywordParam, resultsPerPageParam) => {
    setLoading(true);
    setViewCatalog(false); // Ensure catalog view is disabled when searching
    const url = `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/Shop?keyword=${encodeURIComponent(
      keywordParam
    )}&resultsPerPage=${resultsPerPageParam}&pageNumber=${pageNum}`;

    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

      const jsonResponse = await response.json();
      let responseData = jsonResponse.body ? JSON.parse(jsonResponse.body) : jsonResponse;

      if (responseData.findItemsAdvancedResponse) {
        const items = responseData.findItemsAdvancedResponse[0].searchResult[0].item || [];
        setItems(items);
      } else {
        setError('Invalid response format');
      }
    } catch (error) {
      setError('Error fetching items');
    } finally {
      setLoading(false);
    }
  };

  // Fetch sponsor's catalog items
  const fetchCatalog = async () => {
    if (!selectedSponsorEmail) {
      alert('Please select a sponsor organization.');
      return;
    }
    setLoading(true);
    setSearchTriggered(false); // Ensure search view is disabled when viewing catalog
    const url = `${addItemUrl}?sponsorEmail=${encodeURIComponent(selectedSponsorEmail)}`;

    try {
      const response = await fetch(url, { method: 'GET' });
      const catalogData = await response.json();
      if (response.ok) {
        setCatalogItems(catalogData);
        setViewCatalog(true); // Trigger catalog view
      } else {
        setError('Failed to fetch catalog');
      }
    } catch (error) {
      setError('Error fetching catalog');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Add item to catalog
  const addItemToCatalog = async (item) => {
    if (!selectedSponsorEmail) {
      alert('Please select a sponsor organization.');
      return;
    }
    const payload = {
      sponsorEmail: selectedSponsorEmail,
      itemTitle: item.title[0],
      itemUrl: item.viewItemURL[0],
      itemImageUrl: item.galleryURL ? item.galleryURL[0] : '',
      itemPrice: item.sellingStatus[0].currentPrice[0].__value__,
      currency: item.sellingStatus[0].currentPrice[0]['@currencyId'],
      action: 'add',
    };

    try {
      const response = await fetch(addItemUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        alert('Item added to catalog successfully!');
      } else {
        alert('Failed to add item to catalog.');
      }
    } catch (error) {
      console.error('Error adding item to catalog:', error);
    }
  };

  // Remove item from catalog
  const removeItemFromCatalog = async (itemTitle) => {
    if (!selectedSponsorEmail) {
      alert('Please select a sponsor organization.');
      return;
    }
    const payload = {
      sponsorEmail: selectedSponsorEmail,
      itemTitle: itemTitle,
      action: 'remove',
    };

    try {
      const response = await fetch(addItemUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        alert('Item removed from catalog successfully!');
        fetchCatalog(); // Refresh catalog after removing item
      } else {
        alert('Failed to remove item from catalog.');
      }
    } catch (error) {
      console.error('Error removing item from catalog:', error);
    }
  };

  // Start editing a custom title
  const startEdit = (catalogItem) => {
    setEditingTitles({
      ...editingTitles,
      [catalogItem.itemTitle]: true,
    });
    setCustomTitles({
      ...customTitles,
      [catalogItem.itemTitle]: catalogItem.customTitle || '', // Initialize with existing custom title if any
    });
  };

  // Cancel editing
  const cancelEdit = (catalogItem) => {
    setEditingTitles({
      ...editingTitles,
      [catalogItem.itemTitle]: false,
    });
    setCustomTitles((prevCustomTitles) => {
      const updatedCustomTitles = { ...prevCustomTitles };
      delete updatedCustomTitles[catalogItem.itemTitle];
      return updatedCustomTitles;
    });
  };

  // Save custom title
  const saveCustomTitle = async (catalogItem) => {
    if (!selectedSponsorEmail) {
      alert('Please select a sponsor organization.');
      return;
    }
    const newCustomTitle = customTitles[catalogItem.itemTitle];
    const payload = {
      sponsorEmail: selectedSponsorEmail,
      itemTitle: catalogItem.itemTitle,
      customTitle: newCustomTitle,
      action: 'update',
    };

    try {
      const response = await fetch(addItemUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        alert('Custom title updated successfully!');
        // Update the catalog item locally
        setCatalogItems(
          catalogItems.map((item) =>
            item.itemTitle === catalogItem.itemTitle
              ? { ...item, customTitle: newCustomTitle }
              : item
          )
        );
        // Exit edit mode
        setEditingTitles({
          ...editingTitles,
          [catalogItem.itemTitle]: false,
        });
        // Clear the custom title input
        setCustomTitles((prevCustomTitles) => {
          const updatedCustomTitles = { ...prevCustomTitles };
          delete updatedCustomTitles[catalogItem.itemTitle];
          return updatedCustomTitles;
        });
      } else {
        alert('Failed to update custom title.');
      }
    } catch (error) {
      console.error('Error updating custom title:', error);
    }
  };

  useEffect(() => {
    if (searchTriggered) {
      fetchItems(pageNumber, keyword, resultsPerPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const handleSearch = (e) => {
    e.preventDefault();
    if (!selectedSponsorEmail) {
      alert('Please select a sponsor organization.');
      return;
    }
    setPageNumber(1);
    setSearchTriggered(true);
    fetchItems(1, keyword, resultsPerPage);
  };

  return (
    <div className="shop-container">
      <h1>Admin Shop</h1>
      <div className="form-container">
        <label htmlFor="sponsor-select">Select Sponsor Organization:</label>
        <select
          id="sponsor-select"
          value={selectedSponsorOrg}
          onChange={(e) => setSelectedSponsorOrg(e.target.value)}
        >
          <option value="">--Select Sponsor--</option>
          {sponsors.map((sponsor) => (
            <option key={sponsor.email} value={sponsor.sponsorOrgName}>
              {sponsor.sponsorOrgName}
            </option>
          ))}
        </select>
      </div>

      <div className="form-container">
        <form onSubmit={handleSearch}>
          <input
            type="text"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder="Search by keyword"
          />
          <input
            type="number"
            value={resultsPerPage}
            onChange={(e) => setResultsPerPage(e.target.value)}
            placeholder="Results per page"
            min="1"
          />
          <button type="submit">Search</button>
        </form>
      </div>

      <button className="catalog-button" onClick={fetchCatalog}>
        View Sponsor's Catalog
      </button>

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <>
          {searchTriggered && !viewCatalog && items.length > 0 && (
            <div className="item-grid">
              {items.map((item, index) => (
                <div key={index} className="item-card">
                  <h3>{item.title[0]}</h3>
                  {item.galleryURL && item.galleryURL[0] && (
                    <img src={item.galleryURL[0]} alt={item.title[0]} />
                  )}
                  {conversionRate ? (
                    <p>
                      Price:{' '}
                      {Math.round(
                        parseFloat(item.sellingStatus[0].currentPrice[0].__value__) /
                          conversionRate
                      )}{' '}
                      Points
                    </p>
                  ) : (
                    <p>
                      Price: {item.sellingStatus[0].currentPrice[0].__value__}{' '}
                      {item.sellingStatus[0].currentPrice[0]['@currencyId']}
                    </p>
                  )}
                  <a
                    href={item.viewItemURL[0]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Item
                  </a>
                  <button onClick={() => addItemToCatalog(item)}>
                    Add to Catalog
                  </button>
                </div>
              ))}
            </div>
          )}

          {viewCatalog && (
            <>
              <h2>{selectedSponsorOrg}'s Catalog</h2>
              <div className="item-grid">
                {catalogItems.length > 0 ? (
                  catalogItems.map((catalogItem, index) => (
                    <div key={index} className="item-card">
                      {editingTitles[catalogItem.itemTitle] ? (
                        <>
                          <input
                            type="text"
                            value={customTitles[catalogItem.itemTitle]}
                            onChange={(e) =>
                              setCustomTitles({
                                ...customTitles,
                                [catalogItem.itemTitle]: e.target.value,
                              })
                            }
                          />
                          <button onClick={() => saveCustomTitle(catalogItem)}>
                            Save
                          </button>
                          <button onClick={() => cancelEdit(catalogItem)}>
                            Cancel
                          </button>
                        </>
                      ) : (
                        <>
                          <h3>{catalogItem.customTitle || catalogItem.itemTitle}</h3>
                          <button onClick={() => startEdit(catalogItem)}>
                            Edit Title
                          </button>
                        </>
                      )}
                      {catalogItem.itemImageUrl && (
                        <img
                          src={catalogItem.itemImageUrl}
                          alt={catalogItem.itemTitle}
                        />
                      )}
                      {conversionRate ? (
                        <p>
                          Price:{' '}
                          {Math.round(
                            parseFloat(catalogItem.itemPrice) / conversionRate
                          )}{' '}
                          Points
                        </p>
                      ) : (
                        <p>
                          Price: {catalogItem.itemPrice} {catalogItem.currency}
                        </p>
                      )}
                      <a
                        href={catalogItem.itemUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Item
                      </a>
                      <button
                        onClick={() =>
                          removeItemFromCatalog(catalogItem.itemTitle)
                        }
                      >
                        Remove from Catalog
                      </button>
                    </div>
                  ))
                ) : (
                  <p>No items in the catalog.</p>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AdminShop;
