// Checkout.js

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Checkout.css';

const Checkout = () => {
  const [bioData, setBioData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [errorMessages, setErrorMessages] = useState("");
  const [successMessages, setSuccessMessages] = useState({}); 
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchCheckoutData = async () => {
      try {
        const email = location.state?.email || localStorage.getItem('email');

        if (email) {
          localStorage.setItem('email', email);
        } else {
          setError('Email is undefined');
          setLoading(false);
          return;
        }

        // Fetch user bio data
        const bioResponse = await fetch(
          `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/bio?email=${encodeURIComponent(email)}`
        );
        if (!bioResponse.ok) {
          const errorBody = await bioResponse.text();
          throw new Error(`Failed to fetch bio data: ${errorBody}`);
        }
        const bioData = await bioResponse.json();
        setBioData(bioData);
        setUserRole(bioData.userRole);
        
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    fetchCheckoutData();
  }, [location.state]);

  if (loading) {
    return <p className="loading">Loading...</p>;
  }

  return (
    <div className="checkout-container">
      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <>

          <h1>Checkout</h1>
        </>
      )}
    </div>
  );
};

export default Checkout;
