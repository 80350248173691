import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ForgotPassword.css'; // Import the external CSS

async function logPWResetStatus(email) {
  const apiUrl = "https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/passwordResetLogging";

  const requestData = {
    httpMethod: "POST",
    body: {
      email: email,
      reason: "Forgot Password Request",
      changedBy: email
    }
  };

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    });

    if (response.ok) {
      const responseData = await response.json();
      console.log('Password reset logged successfully:', responseData);
    } else {
      console.error('Error logging password reset: Request failed with status', response.status);
    }
  } catch (error) {
    console.error('Error logging password reset: Could not connect to the API Gateway', error);
  }
}

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState(null);
  const [showErrors, setShowErrors] = useState(false); // Controls error display
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setShowErrors(true); // Display errors after clicking the button
    setError(null);

    try {
      // Fetch user information
      const userInfoResponse = await fetch(`https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/bio?email=${encodeURIComponent(email)}`);
      if (!userInfoResponse.ok) {
        throw new Error('Failed to fetch user details. Please check the email address.');
      }

      const userInfo = await userInfoResponse.json();

      // Validate first and last name
      if (userInfo.firstName !== firstName || userInfo.lastName !== lastName) {
        throw new Error('First name or last name does not match our records.');
      }

      // Call the API endpoint to reset the password
      const resetPasswordResponse = await fetch("https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/users/create-user", {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          password: newPassword
        })
      });

      if (!resetPasswordResponse.ok) {
        const errorMessage = await resetPasswordResponse.text();
        throw new Error(`Error resetting password: ${errorMessage}`);
      }

      // Log the password reset request immediately after the password reset
      await logPWResetStatus(email);

      alert('Password successfully reset. Please log in with your new password.');
      navigate('/login');
    } catch (err) {
      console.error('Error resetting password:', err);
      setError(err.message || 'Failed to reset password.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-form-container">
        <h2 className="forgot-password-title">Forgot Password</h2>
        <p className="forgot-password-instructions">
          To reset your password, please provide the correct email, first and last name associated with the account, and the new password.
        </p>
        <form onSubmit={handleResetPassword} className="forgot-password-form">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              className="form-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Enter your email"
            />
          </div>
          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              id="firstName"
              className="form-input"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              placeholder="Enter your first name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              id="lastName"
              className="form-input"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              placeholder="Enter your last name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="newPassword">New Password</label>
            <input
              type="password"
              id="newPassword"
              className="form-input"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              placeholder="Enter your new password"
            />
          </div>
          {showErrors && error && <p className="error-message">{error}</p>}
          <button type="submit" className="forgot-password-button" disabled={loading}>
            {loading ? 'Resetting Password...' : 'Reset Password'}
          </button>
        </form>
        <div className="forgot-password-footer">
          <button onClick={() => navigate('/login')} className="footer-button">
            Back to Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
