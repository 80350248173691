// Dashboard.js
import React from 'react';
import { Link } from 'react-router-dom';
import './AuditLogs.css'; // Optional: Add custom styling

const AuditDashboard = () => {
  return (
    <div className="dashboard-container">
      <h1>Audit Logs</h1>
      <div className="button-container">
        <Link to="/Audit">
          <button className="dashboard-button">Login Attempts</button>
        </Link>
        <Link to="/PWLogs">
          <button className="dashboard-button">Password Changes</button>
        </Link>
        <Link to="/applications">
          <button className="dashboard-button">Applications</button>
        </Link>
        <Link to="/pointReports">
          <button className="dashboard-button">Points Changes</button>
        </Link>
      </div>
    </div>
  );
};

export default AuditDashboard;