// Applications.js

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Applications.css';

const Applications = () => {
  const [bioData, setBioData] = useState(null);
  const [sponsorData, setSponsorData] = useState([]);
  const [selectedSponsor, setSelectedSponsor] = useState(null);
  const [applicationFormData, setApplicationFormData] = useState([]);
  const [sponsorQuestions, setSponsorQuestions] = useState(null);
  const [userAnswers, setUserAnswers] = useState({});
  const [userDetails, setUserDetails] = useState({ firstName: '', lastName: '', email: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchApplicationsData = async () => {
      try {
        const email = location.state?.email || localStorage.getItem('email');

        if (email) {
          localStorage.setItem('email', email);
        } else {
          setError('Email is undefined');
          setLoading(false);
        }

        // Fetch user bio data
        const bioResponse = await fetch(
          `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/bio?email=${encodeURIComponent(email)}`
        );
        if (!bioResponse.ok) {
          const errorBody = await bioResponse.text();
          throw new Error(`Failed to fetch bio data: ${errorBody}`);
        }
        const bioData = await bioResponse.json();
        setBioData(bioData);
        setUserDetails({ firstName: bioData.firstName, lastName: bioData.lastName, email: bioData.email });
        setUserRole(bioData.userRole);

        // Fetch sponsor data
        const sponsorResponse = await fetch(
          `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/sponsors`
        );
        if (!sponsorResponse.ok) {
          const errorBody = await sponsorResponse.text();
          throw new Error(`Failed to fetch sponsor data: ${errorBody}`);
        }
        const sponsorData = await sponsorResponse.json();
        setSponsorData(sponsorData);

        // Fetch application form data
        const applicationFormResponse = await fetch(
          `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/applicationForms`
        );
        if (!applicationFormResponse.ok) {
          const errorBody = await applicationFormResponse.text();
          throw new Error(`Failed to fetch application form data: ${errorBody}`);
        }
        const applicationFormData = await applicationFormResponse.json();
        setApplicationFormData(applicationFormData);

        // Automatically select sponsorOrg if the user is a Sponsor
        if (bioData.userRole === 'Sponsor') {
          const userSponsorOrgName = bioData.sponsorOrg;
          const selectedForm = applicationFormData.find(form => form.sponsorOrgName === userSponsorOrgName);
          setSelectedSponsor({ name: userSponsorOrgName });
          setSponsorQuestions(selectedForm || null);
        }

      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    fetchApplicationsData();
  }, [location.state]);

  const handleSponsorSelect = (event) => {
    const sponsorID = parseInt(event.target.value);
    
    const selectedSponsorData = sponsorData.find(
      (sponsor) => sponsor.sponsorOrgID === sponsorID
    );

    setSelectedSponsor(selectedSponsorData ? { id: selectedSponsorData.sponsorOrgID, name: selectedSponsorData.sponsorOrgName } : null);
  
    // Filter applicationFormData to find the specific form for the selected sponsor
    const selectedForm = applicationFormData.find(
      (form) => form.sponsorOrgID === sponsorID
    );
    
    setSponsorQuestions(selectedForm || null);
  };

  const handleAnswerChange = (event, questionKey) => {
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionKey]: event.target.value,
    }));
  };

  const handleEditQuestion = (index) => {
    setIsEditing(true);
    
    // Check if sponsorQuestions exists and is not null
    const initialAnswers = {};
    if (sponsorQuestions) {
      for (let i = 1; i <= 8; i++) {
        const questionKey = `question${i}`;
        initialAnswers[questionKey] = sponsorQuestions[questionKey] || ''; // Initialize with current question text
      }
    }
  
    setUserAnswers(initialAnswers);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setUserAnswers({});
  };

  const handleSave = async () => {
    const updatedQuestions = { ...sponsorQuestions, ...userAnswers };
    const applicationData = {
      sponsorOrgName: selectedSponsor?.name || '',
      ...updatedQuestions,
    };

    console.log("Saved Application Form Data: ", applicationData);

    try {
      const response = await fetch('https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/applicationForms', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(applicationData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log('API Response:', result);
      setSponsorQuestions(updatedQuestions);
      setIsEditing(false);
      // Optionally show success message
    } catch (error) {
      console.error('Error saving application:', error);
      // Handle error case, e.g., show an error message to the user
    }

    setIsEditing(false);
  };

  const handleSubmit = async () => {
    // Prepare the data to be sent
    const applicationData = {
      sponsorOrgName: selectedSponsor?.name || '',
      applicantFirstName: userDetails.firstName,
      applicantLastName: userDetails.lastName,
      applicantEmail: userDetails.email,
      ...Object.fromEntries(
        Object.entries(userAnswers).filter(([_, answer]) => answer) // Only include questions with answers
      ),
    };

    try {
      const response = await fetch(
        'https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/submittedApplications',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(applicationData),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to submit application: ${response.statusText}`);
      }

      const result = await response.json();
      console.log('Application submitted successfully:', result);
      // Optionally, show a success message or navigate to another page
    } catch (error) {
      console.error('Error submitting application:', error);
      // Handle error case
    }
  };

  const gotoSubmittedApplications = () => {
    navigate('/Submitted_Applications');
  };

  if (loading) {
    return <p className="loading">Loading...</p>;
  }

  return (
    <div className="applications-container">
      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <>
          {/* Navigation Button */}
          <button className="view-submitted-button" onClick={gotoSubmittedApplications}>
            View Submitted Applications
          </button>
  
          <h1>Applications</h1>

          <div className='sponsor-org-header'>
            {userRole === 'Sponsor' && bioData && (
              <h2>Sponsor Organization: {bioData.sponsorOrg}</h2>
            )}
          </div>

          {/* Render based on userRole */}
          {(userRole === 'Driver' || userRole === 'Admin') && (
            <div className="submitted-change-sponsor">
              <div className="form-group">
                <label htmlFor="sponsorSelect">Select a sponsor:</label>
                <select
                  id="sponsorSelect"
                  value={selectedSponsor?.id || ''}
                  onChange={handleSponsorSelect}
                  className="form-control"
                >
                  <option value="">-- Select Sponsor --</option>
                  {sponsorData.map((sponsor) => (
                    <option key={sponsor.sponsorOrgID} value={sponsor.sponsorOrgID}>
                      {sponsor.sponsorOrgName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
  
          {/* Application Questions */}
          <div className="application-questions">
            <h2>Application Questions</h2>

            {selectedSponsor ? (
              (userRole === 'Sponsor' || userRole === 'Admin') ? (
                // Render questions for Sponsor and Admin role with labels
                <>
                  {Array.from({ length: 8 }).map((_, index) => {
                    const questionKey = `question${index + 1}`;
                    const questionValue = sponsorQuestions && sponsorQuestions[questionKey] !== undefined ? sponsorQuestions[questionKey] : null;

                    return (
                      <div key={questionKey} className="form-group">
                        {isEditing || questionValue ? (
                          <label htmlFor={questionKey} className='question-header'>{`Question ${index + 1}`}</label>
                        ) : null}
                        {isEditing ? (
                          <>
                            <div className='editing-textarea'>
                              <textarea
                                id={questionKey}
                                value={userAnswers[questionKey] !== undefined ? userAnswers[questionKey] : ''}
                                onChange={(event) => handleAnswerChange(event, questionKey)}
                                className="form-control"
                                rows="4"
                              ></textarea>
                            </div>
                          </>
                        ) : (
                          questionValue && <p className='question-value'>{questionValue}</p>
                        )}
                      </div>
                    );
                  })}
                  {isEditing && (
                    <>
                      <button onClick={handleSave} className="save-button">Save Changes</button>
                      <button onClick={handleCancelEdit} className="cancel-button">Cancel</button>
                    </>
                  )}
                  {!isEditing && <button onClick={handleEditQuestion} className="edit-button">Edit Questions</button>}
                </>
              ) : (
                // Render answers for Driver role
                <>
                  <div className="user-details">
                    <div className="form-group">
                      <label htmlFor="firstName">First Name:</label>
                      <input
                        type="text"
                        id="firstName"
                        value={userDetails.firstName}
                        readOnly
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="lastName">Last Name:</label>
                      <input
                        type="text"
                        id="lastName"
                        value={userDetails.lastName}
                        readOnly
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email:</label>
                      <input
                        type="email"
                        id="email"
                        value={userDetails.email}
                        readOnly
                        className="form-control"
                      />
                    </div>
                  </div>
                  {sponsorQuestions && Object.entries(sponsorQuestions).map(([key, value]) => {
                    if (key.startsWith('question') && value !== null) {
                      return (
                        <div key={key} className="form-group">
                          <label className='question-header'>{`Question ${key.slice(-1)}`}</label>
                          <p className='question-value'>{value}</p>
                          <textarea
                            id={`${key}-answer`}
                            value={userAnswers[key] || ''}
                            onChange={(event) => handleAnswerChange(event, key)}
                            className="form-control"
                            rows="4"
                            placeholder="Type your answer here"
                          ></textarea>
                        </div>
                      );
                    }
                    return null;
                  })}
                  <button onClick={handleSubmit} className="submit-button">
                    Submit Application
                  </button>
                </>
              )
            ) : (
              <p>Please select a sponsor to see the application questions.</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Applications;
