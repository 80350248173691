import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Signup.css'; // Import the external CSS

const Signup = () => {
  const [firstName, setFirstName] = useState('');  // State for first name
  const [lastName, setLastName] = useState('');  // State for last name
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);  // To handle errors
  const [loading, setLoading] = useState(false);  // To handle loading state
  const navigate = useNavigate();  // Use useNavigate for redirection

  const handleSignup = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // Call Lambda function to create the user in Cognito
      await fetch(
        'https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/users/create-user',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: email,
            password: password,
          }),
        }
      );

      // Make a POST request to the Lambda function to add user to the database
      const response = await fetch('https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/bio', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          firstName: firstName,
          lastName: lastName,
          userRole: 'Driver',  // Since the role is 'Driver' by default
        }),
      });

      if (response.ok) {
        // Redirect to dashboard
        navigate('/dashboard', { state: { email: email } });
      } else {
        const errorMessage = await response.text();
        setError(`Error: ${errorMessage}`);
      }
    } catch (err) {
      console.error('Error during signup:', err);
      setError(err.message || 'Failed to sign up. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-form-container">
        <h2 className="signup-title">Sign Up for DriveRewards</h2>

        {/* Informational Message */}
        <p className="info-message">
          **Note:** Only <strong>Drivers</strong> can create accounts through this signup page.
          Potential <strong>Sponsors</strong> need to reach out directly for an account.
        </p>

        <form onSubmit={handleSignup} className="signup-form">
          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              id="firstName"
              className="form-input"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              placeholder="Enter your first name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              id="lastName"
              className="form-input"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              placeholder="Enter your last name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              className="form-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Enter your email"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              className="form-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Enter your password"
            />
          </div>
          {error && <p className="error-message">{error}</p>} {/* Display error message */}
          <button type="submit" className="signup-button" disabled={loading}>
            {loading ? 'Signing up...' : 'Sign Up'}
          </button>
        </form>
        <div className="signup-footer">
          <button
            onClick={() => navigate('/login')}
            className="footer-button"
          >
            Already have an account? Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default Signup;
