// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Dashboard from './pages/Dashboard/Dashboard';
import About from './pages/About/About';
import CreateAccount from './pages/Create Account/CreateAccount';
import Bio from './pages/Bio/Bio';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Signup from './pages/Login/Signup';
import ForgotPassword from './pages/Login/ForgotPassword';
import ConfirmSignup from './pages/Login/ConfirmSignup';
import Goodbye from './pages/Goodbye/Goodbye';
import Layout from './buttons/Layout';
import AuditLogs from './pages/AuditLogs/AuditLogs';
import Points from './pages/Points/Points';
import Rewards_Penalties from './pages/Rewards_Penalties/Rewards_Penalties';
import Applications from './pages/Applications/Applications';
import Submitted_Applications from './pages/Applications/Submitted_Applications';
import Connect from './pages/Connect/Connect';
import PointReports from './pages/AuditLogs/PointReports';
import AuditDashboard from './pages/AuditLogs/AuditDash';
import Alerts from './pages/Alerts/Alerts';
import Shop from './pages/Shop/Shop';
import TimeoutSettings from './pages/TimeoutSettings/TimeoutSettings';
import TimeoutGoodbye from './pages/TimeoutGoodbye/TimeoutGoodbye';
import RemoveDriver from './pages/Remove Driver/RemoveDriver';
import Checkout from './pages/Checkout/Checkout';
import Orders from './pages/Orders/Orders';
import DriverShop from './pages/Shop/DriverShop';
import PWLogs from './pages/AuditLogs/PWLogs';
import UserMgt from './pages/userMgt/userMgt';
import SponsorManagement from './pages/Sponsor Management/SponsorManagement';
import AdminShop from './pages/Shop/AdminShop'

function App() {
  const [driverView, setDriverView] = useState(() => {
    const saved = localStorage.getItem('driverView');
    return saved ? JSON.parse(saved) : false;
  });

  useEffect(() => {
    localStorage.setItem('driverView', JSON.stringify(driverView));
  }, [driverView]);

  const toggleDriverView = (value) => {
    setDriverView(value);
  };

  return (
    <div>
      <Routes>
        {/* Routes without navbar */}
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/confirm-signup" element={<ConfirmSignup />} />
        <Route path="/goodbye" element={<Goodbye />} />
        <Route path="/timeout-goodbye" element={<TimeoutGoodbye />} />
        {/* Routes with navbar and Layout */}
        <Route
          path="/dashboard"
          element={
            <Layout driverView={driverView}>
              <Dashboard driverView={driverView} setDriverView={setDriverView} />
            </Layout>
          }
        />
        <Route
          path="/alerts"
          element={
            <Layout driverView={driverView}>
              <Alerts />
            </Layout>
          }
        />
        <Route
          path="/shop"
          element={
            <Layout driverView={driverView}>
              <Shop />
            </Layout>
          }
        />
        <Route
          path="/driverShop"
          element={
            <Layout>
              <DriverShop />
            </Layout>
          }
        />
        <Route
          path="/adminShop"
          element={
            <Layout>
              <AdminShop />
            </Layout>
          }
        />
        <Route
          path="/checkout"
          element={
            <Layout>
              <Checkout />
            </Layout>
          }
        />
        <Route
          path="/orders"
          element={
            <Layout>
              <Orders />
            </Layout>
          }
        />
        <Route
          path="/userMgt"
          element={
            <Layout>
              <UserMgt />
            </Layout>
          }
        />
        <Route
          path="/about"
          element={
            <Layout driverView={driverView}>
              <About />
            </Layout>
          }
        />
        <Route
          path="/create-account"
          element={
            <Layout driverView={driverView}>
              <CreateAccount />
            </Layout>
          }
        />
        <Route
          path="/bio"
          element={
            <Layout driverView={driverView}>
              <Bio />
            </Layout>
          }
        />
        <Route
          path="/connect"
          element={
            <Layout driverView={driverView}>
              <Connect />
            </Layout>
          }
        />
        <Route
          path="/points"
          element={
            <Layout driverView={driverView}>
              <Points />
            </Layout>
          }
        />
        <Route
          path="/rewards_penalties"
          element={
            <Layout driverView={driverView}>
              <Rewards_Penalties />
            </Layout>
          }
        />
        <Route
          path="/applications"
          element={
            <Layout driverView={driverView}>
              <Applications />
            </Layout>
          }
        />
        <Route
          path="/submitted_applications"
          element={
            <Layout driverView={driverView}>
              <Submitted_Applications />
            </Layout>
          }
        />
        <Route
          path="/remove-driver"
          element={
            <Layout driverView={driverView}>
              <RemoveDriver />
            </Layout>
          }
        />
        <Route
          path="/audit"
          element={
            <Layout driverView={driverView}>
              <AuditLogs />
            </Layout>
          }
        />
        <Route
          path="/auditDash"
          element={
            <Layout driverView={driverView}>
              <AuditDashboard />
            </Layout>
          }
        />
        <Route
          path="/PWLogs"
          element={
            <Layout>
              <PWLogs />
            </Layout>
          }
        />
        <Route
          path="/PointReports"
          element={
            <Layout driverView={driverView}>
              <PointReports />
            </Layout>
          }
        />
        <Route
          path="/timeout-settings"
          element={
            <Layout driverView={driverView}>
              <TimeoutSettings />
            </Layout>
          }
        />
        <Route
          path="/sponsor-management"
          element={
            <Layout driverView={driverView}>
              <SponsorManagement />
            </Layout>
          }
        />
      </Routes>
    </div>
  );
}

export default function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}
