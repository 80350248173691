import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './AuditLogs.css';

const Points = () => {
  const location = useLocation();
  const [bioData, setBioData] = useState(null);
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [pointHistory, setPointHistory] = useState([]);
  const [filteredPointHistory, setFilteredPointHistory] = useState([]);
  const [userPoints, setUserPoints] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // State for date range
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const email = location.state?.email || localStorage.getItem('userEmail');
        
        if (!email) {
          throw new Error('No email provided');
        }

        const bioResponse = await fetch(`https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/bio?email=${encodeURIComponent(email)}`);
        if (!bioResponse.ok) {
          const errorBody = await bioResponse.text();
          throw new Error(`Failed to fetch bio data: ${errorBody}`);
        }
        const bioData = await bioResponse.json();
        setBioData(bioData);

        const role = bioData.userRole;
        let usersResponse;

        if (role === 'Admin') {
          usersResponse = await fetch('https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/users');
        } else if (role === 'Sponsor') {
          usersResponse = await fetch(`https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/users?sponsorOrg=${encodeURIComponent(bioData.sponsorOrg)}`);
        }

        if (!usersResponse.ok) {
          throw new Error('Failed to fetch user list');
        }
        const usersData = await usersResponse.json();
        setUserList(usersData);

        localStorage.setItem('userEmail', email);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load user list');
      }
    };
  
    fetchUserData();
  }, [location.state]);

  const fetchPointHistory = async (email) => {
    setLoading(true);
    setError(null);

    try {
      const historyResponse = await fetch(`https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/pointHistory?email=${encodeURIComponent(email)}`);
      if (!historyResponse.ok) {
        throw new Error('Failed to fetch point history');
      }
      const pointHistoryData = await historyResponse.json();
      setPointHistory(pointHistoryData);
      filterPointHistory(pointHistoryData); // Filter initially with current date range
    } catch (error) {
      console.error('Error fetching point history:', error);
      setError('Failed to load point history');
    } finally {
      setLoading(false);
    }
  };

  const handleUserClick = (user) => {
    if (selectedUser === user.email) {
      setSelectedUser('');
      setPointHistory([]);
      setFilteredPointHistory([]);
      setUserPoints(0);
    } else {
      setSelectedUser(user.email);
      setUserPoints(user.points);
      fetchPointHistory(user.email);
    }
  };

  const filterPointHistory = (data) => {
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;

    const filteredData = data.filter((history) => {
      const transactionDate = new Date(history.transactionDate);
      if (start && transactionDate < start) return false;
      if (end && transactionDate > end) return false;
      return true;
    });

    setFilteredPointHistory(filteredData);
  };

  const handleSearch = () => {
    filterPointHistory(pointHistory);
  };

  return (
    <div className="points-container">
      <h1>Points Viewer</h1>

      <div className="date-filter">
        <label>Start Date:</label>
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <label>End Date:</label>
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        <button onClick={handleSearch}>Search</button>
      </div>

      <div className="user-list">
        {userList.map((user) => (
          <div key={user.email}>
            <div className="user-item" onClick={() => handleUserClick(user)}>
              <span className="user-name">{user.firstName} {user.lastName} ({user.email})</span>
              <span className="user-points">Points: {user.points}</span>
            </div>

            {selectedUser === user.email && (
              <div className="point-details">
                <h2>Points: {userPoints}</h2>
                {loading ? (
                  <p className="loading">Loading point history...</p>
                ) : error ? (
                  <p className="error-message">{error}</p>
                ) : filteredPointHistory.length > 0 ? (
                  <div className="point-history">
                    <h3>Point History for {user.firstName} {user.lastName}</h3>
                    <table className="history-table">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Made By</th>
                          <th>Change</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredPointHistory.map((history, index) => (
                          <tr key={index}>
                            <td>{new Date(history.transactionDate).toLocaleString()}</td>
                            <td>{history.modifierName}</td>
                            <td>{history.pointsChange} points</td>
                            <td>{history.description}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p>No point history available for this user.</p>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Points;
