import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './AuditLogs.css';

const UserMgt = () => {
  const location = useLocation();
  const [bioData, setBioData] = useState(null); // Logged-in user's bio data
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(''); // Currently selected user email
  const [selectedUserBioData, setSelectedUserBioData] = useState(null); // Selected user's bio data
  const [sponsors, setSponsors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showNewPasswordInput, setShowNewPasswordInput] = useState(false); // For new password input
  const [newPassword, setNewPassword] = useState(''); // New password input state

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const email = location.state?.email || localStorage.getItem('userEmail');
        
        if (!email) {
          throw new Error('No email provided');
        }

        const bioResponse = await fetch(`https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/bio?email=${encodeURIComponent(email)}`);
        if (!bioResponse.ok) {
          const errorBody = await bioResponse.text();
          throw new Error(`Failed to fetch bio data: ${errorBody}`);
        }
        const bioData = await bioResponse.json();
        setBioData(bioData);

        const role = bioData.userRole;
        let usersResponse;

        if (role === 'Admin') {
          usersResponse = await fetch('https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/users');
        } else if (role === 'Sponsor') {
          usersResponse = await fetch(`https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/users?sponsorOrg=${encodeURIComponent(bioData.sponsorOrg)}`);
        }

        if (!usersResponse.ok) {
          throw new Error('Failed to fetch user list');
        }
        const usersData = await usersResponse.json();
        setUserList(usersData);

        const sponsorsResponse = await fetch('https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/sponsors');
        if (!sponsorsResponse.ok) {
          throw new Error('Failed to fetch sponsors');
        }
        const sponsorsData = await sponsorsResponse.json();
        setSponsors(sponsorsData);

        localStorage.setItem('userEmail', email);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data');
      }
    };
  
    fetchUserData();
  }, [location.state]);

  const handleUserClick = async (user) => {
    if (selectedUser === user.email) {
      // Collapse dropdown if the same user is clicked again
      setSelectedUser('');
      setSelectedUserBioData(null);
      return;
    }

    try {
      setSelectedUser(user.email);

      const userBioResponse = await fetch(`https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/bio?email=${encodeURIComponent(user.email)}`);
      if (!userBioResponse.ok) {
        throw new Error('Failed to fetch selected user bio data');
      }
      const selectedUserBioData = await userBioResponse.json();
      setSelectedUserBioData(selectedUserBioData);
    } catch (error) {
      console.error('Error fetching selected user bio data:', error);
      alert('Failed to load selected user bio data');
    }
  };

  const handleChange = (field, value) => {
    setSelectedUserBioData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSaveBio = async () => {
    try {
      if (!selectedUserBioData.firstName || !selectedUserBioData.lastName || !selectedUserBioData.biography) {
        alert('Please complete all required fields');
        return;
      }

      const response = await fetch(`https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/bio`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: selectedUser,
          ...selectedUserBioData,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to save bio data: ${errorText}`);
      }

      alert('Bio saved successfully!');
    } catch (error) {
      console.error('Error saving bio data:', error);
      alert('Failed to save bio data');
    }
  };

  const handleResetPassword = () => {
    setShowNewPasswordInput(true);
  };

  const handleSubmitNewPassword = async () => {
    try {
      const response = await fetch(`https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/users/create-user`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: selectedUserBioData.email,
          password: newPassword,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to reset password: ${errorText}`);
      }

      alert('Password updated successfully!');
      setShowNewPasswordInput(false);
      setNewPassword('');
    } catch (error) {
      console.error('Error updating password:', error);
      alert('Failed to update password');
    }
  };

  return (
    <div className="points-container">
      <h1>User Management</h1>
      <div className="user-list">
        {userList.map((user) => (
          <div key={user.email}>
            <div className="user-item" onClick={() => handleUserClick(user)}>
              <span className="user-name">{user.firstName} {user.lastName} ({user.email})</span>
              <span className="user-points">Role: {user.userRole}</span>
            </div>

            {selectedUser === user.email && selectedUserBioData && (
              <div className="bio-details">
                <h2>Bio Information</h2>
                <div className="bio-info">
                  <label>First Name:</label>
                  <input 
                    type="text"
                    value={selectedUserBioData.firstName || ''}
                    onChange={(e) => handleChange('firstName', e.target.value)}
                  />
                  <label>Last Name:</label>
                  <input 
                    type="text"
                    value={selectedUserBioData.lastName || ''}
                    onChange={(e) => handleChange('lastName', e.target.value)}
                  />
                  <label>Biography:</label>
                  <textarea 
                    value={selectedUserBioData.biography || ''}
                    onChange={(e) => handleChange('biography', e.target.value)}
                  />
                  <label>Hometown:</label>
                  <input 
                    type="text"
                    value={selectedUserBioData.hometown || ''}
                    onChange={(e) => handleChange('hometown', e.target.value)}
                  />
                  <label>Favorite Landmark:</label>
                  <input 
                    type="text"
                    value={selectedUserBioData.favoriteLandmark || ''}
                    onChange={(e) => handleChange('favoriteLandmark', e.target.value)}
                  />
                  <label>Birthday:</label>
                  <input 
                    type="date"
                    value={selectedUserBioData.birthday || ''}
                    onChange={(e) => handleChange('birthday', e.target.value)}
                  />
                  <label>Sponsor:</label>
                  <select
                    value={selectedUserBioData.sponsorOrg || ''}
                    onChange={(e) => handleChange('sponsorOrg', e.target.value)}
                    disabled={bioData?.userRole === 'Sponsor'}
                  >
                    <option value="">Select Sponsor</option>
                    {sponsors.map((sponsor) => (
                      <option key={sponsor.sponsorOrgID} value={sponsor.sponsorOrgName}>
                        {sponsor.sponsorOrgName}
                      </option>
                    ))}
                  </select>
                  <label>
                    <input 
                      type="checkbox"
                      checked={selectedUserBioData.isBirthdayVisible || false}
                      onChange={(e) => handleChange('isBirthdayVisible', e.target.checked)}
                    />
                    Show Birthday
                  </label>
                  <button onClick={handleSaveBio} className="save-button">Save</button>
                  <button onClick={handleResetPassword} className="reset-password-button">Reset Password</button>
                  {showNewPasswordInput && (
                    <div className="new-password-container">
                      <label>New Password:</label>
                      <input 
                        type="password" 
                        value={newPassword} 
                        onChange={(e) => setNewPassword(e.target.value)} 
                      />
                      <button onClick={handleSubmitNewPassword} className="submit-new-password-button">Submit</button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserMgt;
