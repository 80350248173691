import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SignOutButton from '../buttons/SignOutButton';
import './Layout.css';

const Layout = ({ children, driverView }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const email = location.state?.email || localStorage.getItem('email') || '';
  const [userRole, setUserRole] = React.useState('');

  React.useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const response = await fetch(
          `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/bio?email=${encodeURIComponent(email)}`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch user role');
        }
        const data = await response.json();
        setUserRole(data.userRole);

        // Redirect Admin to Admin Shop
        if (data.userRole === 'Admin' && location.pathname === '/shop') {
          navigate('/adminShop', { state: { email } });
        }
      } catch (error) {
        console.error('Error fetching user role:', error);
      }
    };

    if (email) {
      fetchUserRole();
    }
  }, [email, location.pathname, navigate]);

  const currentPath = location.pathname;

  const handleNavigation = (path) => {
    navigate(path, { state: { email } });
  };

  const hideSignOutButtonPaths = ['/', '/login', '/signup', '/goodbye', '/forgot-password', '/confirm-signup'];
  const showSignOutButton = !hideSignOutButtonPaths.includes(currentPath);

  return (
    <div className="layout-container">
      {/* Navigation Bar */}
      <nav className="navbar">
        <div className="navbar-logo" onClick={() => handleNavigation('/dashboard')}>
          DriveRewards
        </div>
        <ul className="navbar-links">
          <li className={currentPath === '/dashboard' ? 'active' : ''} onClick={() => handleNavigation('/dashboard')}>
            Dashboard
          </li>
          <li className={currentPath === '/about' ? 'active' : ''} onClick={() => handleNavigation('/about')}>
            About
          </li>
          <li className={currentPath === '/bio' ? 'active' : ''} onClick={() => handleNavigation('/bio')}>
            Bio
          </li>
          {/* Modify the Shop link based on userRole */}
          <li
            className={(currentPath === '/shop' || currentPath === '/DriverShop') ? 'active' : ''}
            onClick={() => handleNavigation(userRole === 'Driver' ? '/DriverShop' : '/shop')}
          >
            Shop
          </li>
          <li
            className={currentPath === '/orders' ? 'active' : ''}
            onClick={() => handleNavigation('/orders')}
          >
            Orders
          </li>
          <li
            className={currentPath === '/applications' ? 'active' : ''}
            onClick={() => handleNavigation('/applications')}
          >
            Applications
          </li>
          <li className={currentPath === '/timeout-settings' ? 'active' : ''} onClick={() => handleNavigation('/timeout-settings')}>
            Timeout Settings
          </li>
          {/* Conditional Links Based on Driver View and User Role */}
          {driverView ? (
            // Show Driver-only Links in Driver View
            <li className={currentPath === '/connect' ? 'active' : ''} onClick={() => handleNavigation('/connect')}>
              Connect
            </li>
          ) : (
            // Show Sponsor/Admin-only Links when Driver View is off
            (userRole === 'Admin' || userRole === 'Sponsor') && (
              <>
                <li className={currentPath === '/create-account' ? 'active' : ''} onClick={() => handleNavigation('/create-account')}>
                  Create Account
                </li>
                <li className={currentPath === '/remove-driver' ? 'active' : ''} onClick={() => handleNavigation('/remove-driver')}>
                  Remove Driver
                </li>
                <li className={currentPath === '/auditDash' ? 'active' : ''} onClick={() => handleNavigation('/auditDash')}>
                  Audit Logs
                </li>
              </>
            )
          )}

          {userRole === 'Driver' && (
            <>
              <li
                className={currentPath === '/connect' ? 'active' : ''}
                onClick={() => handleNavigation('/connect')}
              >
                Connect
              </li>
            </>
          )}
          
          <li
            className={currentPath === '/points' ? 'active' : ''}
            onClick={() => handleNavigation('/points')}
          >
            Points
          </li>
          { !driverView && (userRole === 'Admin') && (
              <li className={currentPath === '/sponsor-management' ? 'active' : ''} onClick={() => handleNavigation('/sponsor-management')}>
              Sponsor Management
            </li>
          )}
          <li
            className={currentPath === '/userMgt' ? 'active' : ''}
            onClick={() => handleNavigation('/userMgt')}
          >
            Users
          </li>
        </ul>
        {showSignOutButton && <SignOutButton />}
      </nav>

      {/* Page Content */}
      <div className="page-content">
        {children}
      </div>
    </div>
  );
};

export default Layout;
