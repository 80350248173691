import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; 
import './TimeoutSettings.css';

const TimeoutSettings = () => {
    const [timeoutDurationMinutes, setTimeoutDurationMinutes] = useState(15); // Default 15 mins
    const [timeoutEnabled, setTimeoutEnabled] = useState(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    let inactivityTimer;

    // Retrieve email from location state or localStorage
    const email = location.state?.email || localStorage.getItem('email');
    
    useEffect(() => {
        if (email) {
            localStorage.setItem('email', email);
        } else {
            setError('Email is undefined');
            setLoading(false);
            return;
        }

        // Fetch current timeout settings
        const fetchTimeoutSettings = async () => {
            try {
                const response = await fetch(`https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/timeout?email=${encodeURIComponent(email)}`, {
                    method: 'GET'
                });

                if (!response.ok) throw new Error('Failed to fetch settings');

                const data = await response.json();
                setTimeoutDurationMinutes((data.timeoutDuration || 900) / 60); // Convert seconds to minutes
                setTimeoutEnabled(data.timeoutOn !== undefined ? data.timeoutOn : true);
            } catch (error) {
                setError('Error loading timeout settings');
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchTimeoutSettings();
    }, [email]);

    // Function to handle inactivity logout
    const handleInactivityLogout = () => {
        alert('You have been logged out due to inactivity.');
        navigate('/timeout-goodbye', { state: { timedOut: true } });
    };

    // Start inactivity timer
    const startInactivityTimer = () => {
        clearTimeout(inactivityTimer);
        if (timeoutEnabled) {
            inactivityTimer = setTimeout(handleInactivityLogout, timeoutDurationMinutes * 60 * 1000); // Convert minutes to milliseconds
        }
    };

    useEffect(() => {
        window.addEventListener('mousemove', startInactivityTimer);
        window.addEventListener('keydown', startInactivityTimer);
        window.addEventListener('click', startInactivityTimer);

        startInactivityTimer();

        return () => {
            clearTimeout(inactivityTimer);
            window.removeEventListener('mousemove', startInactivityTimer);
            window.removeEventListener('keydown', startInactivityTimer);
            window.removeEventListener('click', startInactivityTimer);
        };
    }, [timeoutEnabled, timeoutDurationMinutes]);

    // Handle saving the updated settings
    const handleSaveSettings = async () => {
        try {
            const response = await fetch(`https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/timeout`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    email, 
                    timeoutOn: timeoutEnabled, 
                    timeoutDuration: timeoutDurationMinutes * 60 // Convert minutes to seconds for backend
                }),
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('Error response:', errorText);
                throw new Error('Failed to save settings');
            }

            setSuccessMessage('Timeout settings updated successfully');
            setError(null);
        } catch (error) {
            setError('Error saving timeout settings');
            console.error("Fetch error:", error);
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="timeout-settings-container">
            <div className="timeout-settings">
                <h1>Timeout Settings</h1>

                {successMessage && <p className="success-message">{successMessage}</p>}

                <div>
                    <label>
                        Enable Timeout:
                        <input
                            type="checkbox"
                            checked={timeoutEnabled}
                            onChange={(e) => setTimeoutEnabled(e.target.checked)}
                        />
                    </label>
                </div>

                <div>
                    <label>
                        Timeout Duration (minutes):
                        <input
                            type="number"
                            value={timeoutDurationMinutes}
                            onChange={(e) => setTimeoutDurationMinutes(Number(e.target.value))}
                            min="1"
                            step="1"
                        />
                    </label>
                </div>

                <button onClick={handleSaveSettings}>Save Settings</button>
            </div>
        </div>
    );
};

export default TimeoutSettings;
