// Orders.js

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Orders.css';

const Orders = () => {
  const [bioData, setBioData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [sponsorData, setSponsorData] = useState([]);
  const [selectedSponsor, setSelectedSponsor] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [orders, setOrders] = useState([]);
  const [expandedIndices, setExpandedIndices] = useState([]);
  const [decisions, setDecisions] = useState({});
  const [errorMessages, setErrorMessages] = useState("");
  const [successMessages, setSuccessMessages] = useState({}); 
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchOrdersData = async () => {
      try {
        const email = location.state?.email || localStorage.getItem('email');

        if (email) {
          localStorage.setItem('email', email);
        } else {
          setError('Email is undefined');
          setLoading(false);
          return;
        }

        // Fetch user bio data
        const bioResponse = await fetch(
          `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/bio?email=${encodeURIComponent(email)}`
        );
        if (!bioResponse.ok) {
          const errorBody = await bioResponse.text();
          throw new Error(`Failed to fetch bio data: ${errorBody}`);
        }
        const bioData = await bioResponse.json();
        setBioData(bioData);
        setUserRole(bioData.userRole);

        // Fetch sponsor data
        const sponsorResponse = await fetch(
          `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/sponsors`
        );
        if (!sponsorResponse.ok) {
          const errorBody = await sponsorResponse.text();
          throw new Error(`Failed to fetch sponsor data: ${errorBody}`);
        }
        const sponsorData = await sponsorResponse.json();
        setSponsorData(sponsorData);

        // Fetch orders based on user role
        let ordersResponse;
        if (bioData.userRole === 'Driver') {
          ordersResponse = await fetch(
            `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/orders?userID=${encodeURIComponent(bioData.userID)}`
          );
          setSelectedSponsor({ name: bioData.sponsorOrg, id:bioData.sponsorOrgID });
        } else if (bioData.userRole === 'Sponsor') {
          ordersResponse = await fetch(
            `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/orders?sponsorOrgID=${encodeURIComponent(bioData.sponsorOrgID)}`
          );
          setSelectedSponsor({ name: bioData.sponsorOrg, id:bioData.sponsorOrgID });
        } else if (bioData.userRole === 'Admin') {
          ordersResponse = await fetch(
            `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/orders`
          );
        }

        if (!ordersResponse.ok) {
          const errorBody = await ordersResponse.text();
          throw new Error(`Failed to fetch orders: ${errorBody}`);
        }

        const ordersData = await ordersResponse.json();
        console.log("Orders Data: ", ordersData);

        // Sort the orders by timePlaced (descending order)
        const sortedOrders = ordersData.sort((a, b) => {
          const datetimeA = new Date(a.timePlaced);
          const datetimeB = new Date(b.timePlaced);
          return datetimeA - datetimeB;
        });

        setOrders(sortedOrders);

      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    fetchOrdersData();
  }, [location.state]);

  const handleSortChange = (event) => {
    const newSortOrder = event.target.value;
    setSortOrder(newSortOrder);
  
    const sortedOrders = [...orders].sort((a, b) => {
      const datetimeA = new Date(a.timePlaced);
      const datetimeB = new Date(b.timePlaced);
      return newSortOrder === 'asc' ? datetimeA - datetimeB : datetimeB - datetimeA;
    });
  
    setOrders(sortedOrders);
  };

  const handleSponsorSelect = async (event) => {
    const sponsorID = parseInt(event.target.value);
    
    if (sponsorID === 0) {
      setSelectedSponsor(null);
      setOrders([]);
      return;
    }
  
    const selectedSponsorData = sponsorData.find(
      (sponsor) => sponsor.sponsorOrgID === sponsorID
    );
    
    if (!selectedSponsorData) {
      setSelectedSponsor(null);
      setOrders([]); // Clear applications if no valid sponsor is found
      return;
    }
    
    setSelectedSponsor({ id: selectedSponsorData.sponsorOrgID, name: selectedSponsorData.sponsorOrgName });
  
    // Fetch submitted applications filtered by sponsor organization
    try {
      const email = location.state?.email || localStorage.getItem('email');
    
      if (!email) {
        setError('Email is undefined');
        setLoading(false);
        return;
      }
    
      const ordersResponse = await fetch(
        `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/orders?sponsorOrgID=${encodeURIComponent(selectedSponsorData.sponsorOrgID)}`
      );
      if (!ordersResponse.ok) {
        const errorBody = await ordersResponse.text();
        throw new Error(`Failed to fetch orders: ${errorBody}`);
      }
    
      const ordersData = await ordersResponse.json();
      console.log("Orders for ", selectedSponsorData.sponsorOrgName, ": ", ordersData);
      setOrders(ordersData);

    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to load data');
    }
  }; 

  const toggleExpand = (index) => {
    setExpandedIndices(prev => 
      prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]
    );
  };

  if (loading) {
    return <p className="loading">Loading...</p>;
  }

  return (
    <div className="orders-container">
      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <>

          <h1>Orders</h1>
          <div className='sponsor-org-header'>
            {userRole === 'Sponsor' && bioData && (
              <h2>Sponsor Organization: {bioData.sponsorOrg}</h2>
            )}
          </div>

          {userRole === 'Admin' && (
            <div className="change-sponsor">
              <div className="form-group">
                <label htmlFor="sponsorSelect">Select a sponsor:</label>
                <select
                  id="sponsorSelect"
                  value={selectedSponsor?.id || ''}
                  onChange={handleSponsorSelect}
                  className="form-control"
                >
                  <option value="">-- Select Sponsor --</option>
                  {sponsorData.map((sponsor) => (
                    <option key={sponsor.sponsorOrgID} value={sponsor.sponsorOrgID}>
                      {sponsor.sponsorOrgName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}

          {selectedSponsor === null ? (
            <div className='no-selected-message'>
              <p>Please select a sponsor to see orders.</p>
            </div>
          ) : orders.length > 0 ? (
            orders
              .filter((order) => !selectedSponsor || order.sponsorOrgID === selectedSponsor.id)
              .map((order, index) => (
              <div className="order-card" key={index}>
                {userRole === 'Driver' ? (
                  <>
                    <h2>Order {index + 1}</h2>
                    <p><strong>Order Placed:</strong> {new Date(order.timePlaced).toLocaleString()}</p>
                    <p><strong>Point Cost:</strong> {order.pointCost}</p>
                    <p><strong>Currency Cost:</strong> ${order.currencyCost.toFixed(2)}</p>
                    <p>
                      <strong>Order Status: </strong> 
                      <span className={`order-status ${order.orderStatus.toLowerCase()}`}>
                        {order.orderStatus}
                      </span>
                    </p>
                    {order.orderStatus === "Received" && order.timeReceived && (
                      <p><strong>Received:</strong> {new Date(order.timeReceived).toLocaleString()}</p>
                    )}
                    {order.orderStatus === "Shipped" && order.timeShipped && (
                      <p><strong>Shipped:</strong> {new Date(order.timeShipped).toLocaleString()}</p>
                    )}
                    {expandedIndices.includes(index) ? (
                      <>
                        <div className="order-contents">
                          <h3>Order Contents:</h3>
                          <p>{order.orderContents}</p>
                        </div>
                        <button className='collapse-button' onClick={() => toggleExpand(index)}>Collapse</button>
                      </>
                    ) : (
                      <>
                        <button className='expand-button' onClick={() => toggleExpand(index)}>Expand</button>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <h2>Order {index + 1}</h2>
                    <p><strong>Ordered By:</strong> {order.firstName} {order.lastName}</p>
                    <p><strong>Order Placed:</strong> {new Date(order.timePlaced).toLocaleString()}</p>
                    <p><strong>Point Cost:</strong> {order.pointCost}</p>
                    <p><strong>Currency Cost:</strong> ${order.currencyCost.toFixed(2)}</p>
                    <p>
                      <strong>Order Status: </strong> 
                      <span className={`order-status ${order.orderStatus.toLowerCase()}`}>
                        {order.orderStatus}
                      </span>
                    </p>
                    {order.orderStatus === "Received" && order.timeReceived && (
                      <p><strong>Received:</strong> {new Date(order.timeReceived).toLocaleString()}</p>
                    )}
                    {order.orderStatus === "Shipped" && order.timeShipped && (
                      <p><strong>Shipped:</strong> {new Date(order.timeShipped).toLocaleString()}</p>
                    )}
                    {expandedIndices.includes(index) ? (
                      <>
                        <div className="order-contents">
                          <h3>Order Contents:</h3>
                          <p>{order.orderContents}</p>
                        </div>
                        <button className='collapse-button' onClick={() => toggleExpand(index)}>Collapse</button>
                      </>
                    ) : (
                      <>
                        <button className='expand-button' onClick={() => toggleExpand(index)}>Expand</button>
                      </>
                    )}
                  </>
                )}
              </div>
            ))
          ) : (
            <p>No orders found.</p>
          )}

        </>
      )}
    </div>
  );
};

export default Orders;
