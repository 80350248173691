// RemoveDriver.js

import React, { useState, useEffect } from 'react';
import './RemoveDriver.css';

const RemoveDriver = () => {
  const [userList, setUserList] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const sponsorEmail = localStorage.getItem('email');

        // Fetch bio data to get the sponsorOrgID for the sponsor user
        const bioResponse = await fetch(
          `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/bio?email=${encodeURIComponent(sponsorEmail)}`
        );
        if (!bioResponse.ok) {
          throw new Error('Failed to fetch sponsor organization data');
        }
        const bioData = await bioResponse.json();

        // Fetch drivers within the same Sponsor Org
        const driversResponse = await fetch(
          `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/users?sponsorOrg=${encodeURIComponent(bioData.sponsorOrg)}`
        );
        if (!driversResponse.ok) {
          throw new Error('Failed to fetch drivers');
        }
        const driversData = await driversResponse.json();
        setUserList(driversData.filter(user => user.userRole === 'Driver'));
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load drivers');
      } finally {
        setLoading(false);
      }
    };

    fetchDrivers();
  }, []);

  const handleDriverSelect = (event) => {
    setSelectedDriver(event.target.value);
  };

  const handleRemoveDriver = async () => {
    if (!selectedDriver) {
        alert('Please select a driver to remove.');
        return;
    }

    try {
        const response = await fetch(
            'https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/remove-driver',
            {
                method: 'POST', // Use POST as the HTTP method
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: selectedDriver }), // Send the driver email in the body
            }
        );

        if (!response.ok) {
            throw new Error('Failed to remove driver');
        }

        alert('Driver removed successfully!');
        setSelectedDriver('');
        setUserList(userList.filter(user => user.email !== selectedDriver));
    } catch (error) {
        console.error('Error removing driver:', error);
        alert('Error removing driver. Please try again.');
    }
};


  if (loading) {
    return <p className="loading">Loading...</p>;
  }

  return (
    <div className="remove-driver-container">
      <h1>Remove Driver</h1>
      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <div className="driver-details">
          <h2>Select Driver to Remove</h2>
          <select
            className="driver-select"
            value={selectedDriver}
            onChange={handleDriverSelect}
          >
            <option value="">Select Driver</option>
            {userList.map((driver) => (
              <option key={driver.email} value={driver.email}>
                {driver.firstName} {driver.lastName} ({driver.email})
              </option>
            ))}
          </select>
          <button className="remove-button" onClick={handleRemoveDriver}>
            Remove Driver
          </button>
        </div>
      )}
    </div>
  );
};

export default RemoveDriver;
