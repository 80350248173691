// TimeoutGoodbye.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './TimeoutGoodbye.css'; // Import the external CSS

const TimeoutGoodbye = () => {
  const navigate = useNavigate();

  const handleReturnHome = () => {
    navigate('/'); // Redirect to home page
  };

  return (
    <div className="goodbye-container">
      <div className="goodbye-content">
        <h1>You were logged out for inactivity.</h1>
        <h1>Goodbye!</h1>
        <p>Thank you for visiting. We hope to see you again soon!</p>
        <button onClick={handleReturnHome} className="return-button">
          Return to Homepage
        </button>
      </div>
    </div>
  );
};

export default TimeoutGoodbye;
