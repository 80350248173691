import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './CreateAccount.css'; // Import the external CSS

const CreateAccount = () => {
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState('Driver');
  const [userRole, setUserRole] = useState(null);
  const [sponsorOrg, setSponsorOrg] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch current user's role and sponsorOrg via a GET request to Lambda
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const currentUserEmail = location.state?.email || localStorage.getItem('email');

        if (currentUserEmail) {
          localStorage.setItem('email', currentUserEmail);
        } else {
          setError('Email is undefined');
          setLoading(false);
        }

        const response = await fetch(
          `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/bio?email=${encodeURIComponent(currentUserEmail)}`
        );

        if (!response.ok) {
          throw new Error('Failed to fetch user details');
        }

        const userData = await response.json();
        setUserRole(userData.userRole);
        if (userData.userRole === 'Sponsor' || userData.userRole === 'Driver') {
          setSponsorOrg(userData.sponsorOrg); // Prefill sponsorOrg for sponsors and drivers
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [location.state?.email]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Create the Cognito user via the Lambda API
      const response = await fetch(
        'https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/users/create-user',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            password: password,
            email: email,
          }),
        }
      );

      // Add the user to the database via another API call
      const dbResponse = await fetch(
        'https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/bio',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: email,
            firstName: firstName,
            lastName: lastName,
            userRole: role,
            sponsorOrg: sponsorOrg,
            isActive: 1,
          }),
        }
      );

      if (!dbResponse.ok) {
        const errorMessage = await dbResponse.text();
        throw new Error(`Error adding user to database: ${errorMessage}`);
      }

      alert('Account and database entry created successfully!');
    } catch (error) {
      console.error('Error:', error);
      alert(`Failed to create account: ${error.message}`);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="create-account-content">
      <h2>Create Account</h2>
      <form onSubmit={handleSubmit} className="create-account-form">
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="form-input"
            placeholder="Enter email"
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="form-input"
            placeholder="Enter password"
          />
        </div>
        <div className="form-group">
          <label>First Name</label>
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
            className="form-input"
            placeholder="Enter first name"
          />
        </div>
        <div className="form-group">
          <label>Last Name</label>
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
            className="form-input"
            placeholder="Enter last name"
          />
        </div>
        <div className="form-group">
          <label>Role</label>
          <select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            className="form-input"
          >
            <option value="Driver">Driver</option>
            {(userRole === 'Admin' || userRole === 'Sponsor') && (
              <option value="Sponsor">Sponsor</option>
            )}
            {userRole === 'Admin' && <option value="Admin">Admin</option>}
          </select>
        </div>
        <div className="form-group">
          <label>Sponsor Organization</label>
          {userRole === 'Admin' ? (
            <input
              type="text"
              value={sponsorOrg}
              onChange={(e) => setSponsorOrg(e.target.value)}
              required
              className="form-input"
              placeholder="Enter sponsor organization"
            />
          ) : (
            <input
              type="text"
              value={sponsorOrg}
              readOnly
              className="form-input"
            />
          )}
        </div>
        <button type="submit" className="create-account-button">
          Create Account
        </button>
      </form>
    </div>
  );
};

export default CreateAccount;
