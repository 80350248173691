// Rewards_Penalties.js

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Rewards_Penalties.css';

const Points = () => {
  const [bioData, setBioData] = useState(null);
  const [rewardsAndPenaltiesData, setRewardsAndPenaltiesData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState([]); // For tracking changes
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchRewardsPenaltiesData = async () => {
      try {
        const email = location.state?.email || localStorage.getItem('email');

        if (email) {
          localStorage.setItem('email', email);
        } else {
          setError('Email is undefined');
          setLoading(false);
          return;
        }

        const bioResponse = await fetch(
          `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/bio?email=${encodeURIComponent(email)}`
        );
        if (!bioResponse.ok) {
          const errorBody = await bioResponse.text();
          throw new Error(`Failed to fetch bio data: ${errorBody}`);
        }
        const bioData = await bioResponse.json();
        setBioData(bioData);
        setUserRole(bioData.userRole);

        const rewardsAndPenaltiesResponse = await fetch(
          `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/rewardsAndPenalties?sponsorOrg=${encodeURIComponent(bioData.sponsorOrg)}`
        );
        if (!rewardsAndPenaltiesResponse.ok) {
          const errorBody = await rewardsAndPenaltiesResponse.text();
          throw new Error(`Failed to fetch rewards and penalties data: ${errorBody}`);
        }
        const rewardsAndPenaltiesData = await rewardsAndPenaltiesResponse.json();
        setRewardsAndPenaltiesData(rewardsAndPenaltiesData);

        // Pre-fill the editData state
        setEditData(rewardsAndPenaltiesData?.rewards_penalties_list || []);

      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    fetchRewardsPenaltiesData();
  }, []);

  const goToPoints = () => {
    navigate('/points');
  };

  const handleAddRow = () => {
    const newRow = { action: '', associatedPointChange: 0 };
    setEditData([...editData, newRow]);
  };

  const handleRemoveRow = (index) => {
    const updatedData = editData.filter((_, i) => i !== index);
    setEditData(updatedData);
  };

  const handleInputChange = (index, field, value) => {
    const updatedData = [...editData];
    updatedData[index][field] = value;
    setEditData(updatedData);
  };

  const handleSave = async () => {
    try {
        const email = location.state?.email || localStorage.getItem('email');
        const sponsorOrg = bioData?.sponsorOrg;

        if (!email || !sponsorOrg) {
            setError('Email or sponsor organization is undefined');
            return;
        }

        // Prepare the data to send
        const payload = {
            sponsorOrg,
            rewardsPenalties: editData,
        };

        const response = await fetch(
            'https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/rewardsAndPenalties',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            }
        );

        if (!response.ok) {
            const errorBody = await response.text();
            throw new Error(`Failed to save data: ${errorBody}`);
        }

        const result = await response.json();
        console.log('Successfully saved:', result);

        const updatedRewardsAndPenaltiesData = {
            ...rewardsAndPenaltiesData,
            rewards_penalties_list: editData,
        };

        // Update the state with the new data
        setRewardsAndPenaltiesData(updatedRewardsAndPenaltiesData);
        
        setIsEditing(false);
    } catch (error) {
        console.error('Error saving data:', error);
        setError('Failed to save data');
    }
};


  const handleCancel = () => {
    setEditData(rewardsAndPenaltiesData?.rewards_penalties_list || []);
    setIsEditing(false);
  };

  if (loading) {
    return <p className="loading">Loading...</p>;
  }

  const rewards = rewardsAndPenaltiesData?.rewards_penalties_list.filter(item => item.associatedPointChange > 0) || [];
  const penalties = rewardsAndPenaltiesData?.rewards_penalties_list.filter(item => item.associatedPointChange < 0) || [];
  const sponsorOrg = bioData?.sponsorOrg;

  return (
    <div className="rewards-penalties-container">
      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <>
          <h1>Rewards and Penalties</h1>
          {sponsorOrg && <h2 className="sponsor-org-heading">{sponsorOrg}</h2>}

          <button onClick={goToPoints} className="points-button">
            &larr; Back to Points
          </button>

          <div className={`tables-container ${isEditing ? 'edit-tables-container' : ''}`}>
            {isEditing ? (
              <div className={`table-wrapper ${isEditing ? 'edit-table-wrapper' : ''}`}>
                <div className={`table-header ${isEditing ? 'edit-table-header' : ''}`}>
                  <h2>Edit Rewards and Penalties</h2>
                </div>
                <table className="edit-table">
                  <thead>
                    <tr>
                      <th className='remove-row-header'></th>
                      <th className='edit-action-header'>Action</th>
                      <th className='edit-associated-point-change-header'>Associated Point Change</th>
                    </tr>
                  </thead>
                  <tbody>
                    {editData.map((item, index) => (
                      <tr key={index}>
                        <td className = 'remove-row-column'>
                          <button onClick={() => handleRemoveRow(index)} className="remove-row-button">Remove Row</button>
                        </td>
                        <td className = 'edit-action-column'>
                          <input className= 'edit-action-textbox'
                            type="text"
                            value={item.action}
                            onChange={(e) => handleInputChange(index, 'action', e.target.value)}
                          />
                        </td>
                        <td className = 'edit-associated-point-change-column'>
                          <input
                            type="number"
                            value={item.associatedPointChange}
                            onChange={(e) => handleInputChange(index, 'associatedPointChange', parseInt(e.target.value))}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <button onClick={handleAddRow} className="add-row-button">Add Row</button>

                <div className="edit-buttons-container">
                  <button onClick={handleSave} className="rp-save-button">Save</button>
                  <button onClick={handleCancel} className="rp-cancel-button">Cancel</button>
                </div>
              </div>
            ) : (
              <>
                {/* Rewards Table */}
                <div className="table-wrapper">
                  <div className="table-header">
                    <h2>Rewards</h2>
                  </div>
                  {rewards.length > 0 ? (
                    <table className="rewards-table">
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Associated Point Change</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rewards.map((reward, index) => (
                          <tr key={index}>
                            <td>{reward.action}</td>
                            <td>{reward.associatedPointChange}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p className="no-actions-message">{sponsorOrg} doesn't have any rewarded actions set.</p>
                  )}
                </div>

                {/* Penalties Table */}
                <div className="table-wrapper">
                  <div className="table-header">
                    <h2>Penalties</h2>
                  </div>
                  {penalties.length > 0 ? (
                    <table className="penalties-table">
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Associated Point Change</th>
                        </tr>
                      </thead>
                      <tbody>
                        {penalties.map((penalty, index) => (
                          <tr key={index}>
                            <td>{penalty.action}</td>
                            <td>{penalty.associatedPointChange}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p className="no-actions-message">{sponsorOrg} doesn't have any penalized actions set.</p>
                  )}
                </div>
              </>
            )}

            {(userRole === 'Admin' || userRole === 'Sponsor') && !isEditing && (
              <div className="edit-rp">
                <button onClick={() => setIsEditing(true)} className="edit-button">
                  Edit Rewards And Penalties
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Points;
