import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Dashboard.css';

const Dashboard = ({ driverView, setDriverView }) => {
  const [preferredUsername, setPreferredUsername] = useState('');
  const [userRole, setUserRole] = useState('');
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email || localStorage.getItem('userEmail');

  if (email) {
    localStorage.setItem('userEmail', email);
  } else {
    setError('Email is undefined');
    setLoading(false);
  }
    const toggleDriverView = () => {
    setDriverView(!driverView);
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(`https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/bio?email=${encodeURIComponent(email)}`);
        if (!response.ok) throw new Error('Failed to fetch user details');
        const userData = await response.json();
        setPreferredUsername(userData.firstName);
        setUserRole(userData.userRole);
      } catch (error) {
        console.error('Error fetching user details:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchAlerts = async () => {
      try {
        const response = await fetch(`https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/users/alert?email=${encodeURIComponent(email)}`);
        if (!response.ok) throw new Error('Failed to fetch alerts');
        const alertsData = await response.json();
        setAlerts(alertsData);
      } catch (error) {
        console.error('Error fetching alerts:', error);
      }
    };

    if (email) {
      fetchUserDetails();
      fetchAlerts();
    }
  }, [email]);

  const unreadCount = alerts.filter(alert => !alert.read).length;

  const handleAlertClick = () => {
    navigate('/alerts', { state: { alerts } });
  };

  if (loading) {
    return (
      <div className="loading-container">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="dashboard-content">
      <h1>{`Welcome, ${preferredUsername || 'User'}!`}</h1>
      <h2>Your role: {userRole || 'Not assigned'}</h2>
      <p>This is your dashboard. More updates to come!</p>

      <div className="alert-summary-box" onClick={handleAlertClick}>
        <p>You have {unreadCount} unread alerts</p>
        <button>View All Alerts</button>
      </div>

      {/* Conditionally render the Driver View toggle for non-Driver users */}
      {userRole !== 'Driver' && (
        <div className="driver-view-toggle">
          <label>
            <input
              type="checkbox"
              checked={driverView}
              onChange={toggleDriverView}
            />
            Driver View
          </label>
        </div>
      )}
    </div>
  );
};

export default Dashboard;