import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './AuditLogs.css'; // Import the external CSS file

const App = () => {
    const [data, setData] = useState([]);  // State to hold the actual data array
    const [loading, setLoading] = useState(true);  // State to track loading status
    const [error, setError] = useState(null);  // State to handle errors
    const [currentPage, setCurrentPage] = useState(0);  // State to track the current page
    const itemsPerPage = 50;  // Number of entries to display per page
    const location = useLocation();

    const apiUrl = "https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/loginLogging"; 

    useEffect(() => {
        const fetchData = async () => {
            const email = location.state?.email || localStorage.getItem('userEmail');
            try {
                const response = await fetch(apiUrl);
                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }
                const result = await response.json();

                if (result.body) {
                    const parsedData = JSON.parse(result.body);
                    setData(parsedData);
                } else {
                    throw new Error("No data found in the API response");
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [apiUrl]);

    const totalPages = Math.ceil(data.length / itemsPerPage);

    const handleNext = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 0) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const startIndex = currentPage * itemsPerPage;
    const currentData = data.slice(startIndex, startIndex + itemsPerPage);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (currentData.length > 0) {
        const headers = Object.keys(data[0]);

        return (
            <div className="audit-log-container">
                <h1 className="audit-log-title">Login Audit Logs</h1>
                <table className="audit-log-table">
                    <thead>
                        <tr>
                            {headers.map((header) => (
                                <th key={header}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {currentData.map((item, rowIndex) => (
                            <tr key={rowIndex}>
                                {headers.map((header) => (
                                    <td key={`${header}-${rowIndex}`}>
                                        {item[header] !== null ? item[header] : "N/A"}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="pagination-controls">
                    <button onClick={handlePrevious} disabled={currentPage === 0}>
                        Previous
                    </button>
                    <span>Page {currentPage + 1} of {totalPages}</span>
                    <button onClick={handleNext} disabled={currentPage === totalPages - 1}>
                        Next
                    </button>
                </div>
            </div>
        );
    }

    return null;
};

export default App;
