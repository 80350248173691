import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Bio.css';

const Bio = () => {
  const [bioData, setBioData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [sponsorOrg, setSponsorOrg] = useState('');
  const [userRole, setUserRole] = useState(null);
  const [biography, setBiography] = useState('');
  const [hometown, setHometown] = useState('');
  const [favoriteLandmark, setFavoriteLandmark] = useState('');
  const [birthday, setBirthday] = useState('');
  const [isBirthdayVisible, setIsBirthdayVisible] = useState(true);
  const [profilePicture, setProfilePicture] = useState('');  // Base64-encoded image data
  const [profilePictureUrl, setProfilePictureUrl] = useState(''); // URL for previewing the image
  const [isActive, setIsActive] = useState('');
  const [isBioVisible, setIsBioVisible] = useState(true);  // State to control Bio visibility
  const location = useLocation();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const navigate = useNavigate();

  const email = location.state?.email || localStorage.getItem('email');

  if (email) {
    localStorage.setItem('email', email);
    // Proceed with fetching data using the email
  } else {
    setError('Email is undefined');
    setLoading(false);
  }

  useEffect(() => {
    const fetchBioData = async () => {
      try {
        const response = await fetch(
          `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/bio?email=${encodeURIComponent(
            email
          )}`
        );

        if (!response.ok) {
          const errorBody = await response.text();
          throw new Error(`Failed to fetch bio data: ${errorBody}`);
        }

        const data = await response.json();
        console.log('Fetched bio data:', data);
        setBioData(data);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setSponsorOrg(data.sponsorOrg);
        setUserRole(data.userRole);
        setBiography(data.biography || '');
        setHometown(data.hometown || '');
        setFavoriteLandmark(data.favoriteLandmark || '');
        setBirthday(data.birthday || '');
        setIsBirthdayVisible(data.isBirthdayVisible || true);
        setProfilePictureUrl(`data:image/png;base64,${data.profilePicture}`); // Display picture from DB
        setIsActive(data.isActive);
        setIsBioVisible(data.isBioVisible); // Set Bio visibility based on DB
      } catch (error) {
        console.error('Error fetching bio data:', error);
        setError('Failed to load bio data');
      } finally {
        setLoading(false);
      }
    };

    fetchBioData();
  }, [email]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setProfilePicture(reader.result.split(',')[1]); // Store Base64 image data
      setProfilePictureUrl(reader.result); // Preview image
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSaveClick = async () => {
    try {
      const response = await fetch(
        `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/bio`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            firstName,
            lastName,
            sponsorOrg,
            userRole,
            biography,
            hometown,
            favoriteLandmark,
            birthday,
            isBirthdayVisible,
            profilePicture,  // Send Base64 data to backend
            isActive,
            isBioVisible     // Send the bio visibility status
          }),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to update bio data');
      }

      if (!birthday) {
        alert("Birthday is required. Please provide your birthday.");
        return;
      }

      setBioData((prevData) => ({
        ...prevData,
        firstName,
        lastName,
        biography,
        hometown,
        favoriteLandmark,
        birthday,
        isBirthdayVisible,
        profilePicture: profilePictureUrl,  // Update to display the new picture
      }));

      setIsEditing(false);

      // If bio is hidden, update it immediately after saving
      if (!isBioVisible) {
        setBioData((prevData) => ({
          ...prevData,
          biography: '',        // Clear the biography if hidden
          hometown: '',         // Clear the hometown if hidden
          favoriteLandmark: '', // Clear the favoriteLandmark if hidden
          birthday: '',         // Clear the birthday if hidden
        }));
      }
    } catch (error) {
      console.error('Error updating bio data:', error);
      setError('Failed to update bio data');
    }
  };

  const handleDeactivateAccount = async () => {
    try {
      // Call backend to update isActive field to 0 (false)
      const response = await fetch(`https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/bio`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
            firstName,
            lastName,
            sponsorOrg,
            userRole,
            biography,
            hometown,
            favoriteLandmark,
            birthday,
            isBirthdayVisible,
            profilePicture,  // Send Base64 data to backend
            isActive: 0,
            isBioVisible      // Send the bio visibility status
        })
      });

      if (response.ok) {
        // Redirect to login page after successful deactivation
        navigate('/login', { state: { email } });
      } else {
        console.error('Failed to deactivate account');
      }
    } catch (error) {
      console.error('Error deactivating account:', error);
    }
  };


  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="bio-content">
      <h1>Bio Page</h1>

      {profilePictureUrl && (
        <img src={profilePictureUrl} alt="Profile" className="profile-picture-top" />
      )}

      {isEditing ? (
        <div className="bio-form">
          <label>
            First Name:
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </label>
          <label>
            Last Name:
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </label>
          <label>
            Biography:
            <textarea
              value={biography}
              onChange={(e) => setBiography(e.target.value)}
            />
          </label>
          <label>
            Hometown:
            <input
              type="text"
              value={hometown}
              onChange={(e) => setHometown(e.target.value)}
            />
          </label>
          <label>
            Favorite Landmark:
            <input
              type="text"
              value={favoriteLandmark}
              onChange={(e) => setFavoriteLandmark(e.target.value)}
            />
          </label>
          <label>
            Birthday:
            <input
              type="date"
              value={birthday}
              onChange={(e) => setBirthday(e.target.value)}
            />
          </label>
          <label>
            <input
              type="checkbox"
              checked={isBirthdayVisible}
              onChange={(e) => setIsBirthdayVisible(e.target.checked)}
            />
            Show Birthday
          </label>
          <label>
            Profile Picture:
            <input type="file" accept="image/*" onChange={handleFileChange} />
          </label>
          {profilePictureUrl && (
            <img src={profilePictureUrl} alt="Profile Preview" className="profile-preview" />
          )}
          <label>
            <input
              type="checkbox"
              checked={!isBioVisible}  // Reverse the logic here
              onChange={(e) => setIsBioVisible(!e.target.checked)}  // Reverse the logic
            />
            Hide Bio
          </label>
          <button onClick={handleSaveClick} className="save-button">
            Save
          </button>
          <button onClick={() => setIsEditing(false)} className="cancel-button">
            Cancel
          </button>
        </div>
      ) : (
        <div className="bio-info">
          <h3>Email: {bioData.email}</h3>
          <h3>First Name: {bioData.firstName}</h3>
          <h3>Last Name: {bioData.lastName}</h3>
          <h3>Sponsor Org: {bioData.sponsorOrg}</h3>
          {isBioVisible && (
            <>
              <h3>Biography: {bioData.biography}</h3>
              <h3>Hometown: {bioData.hometown}</h3>
              <h3>Favorite Landmark: {bioData.favoriteLandmark}</h3>
              {bioData.isBirthdayVisible && <h3>Birthday: {bioData.birthday}</h3>}
            </>
          )}
          <button onClick={() => setIsEditing(true)} className="edit-button">
            Edit
          </button>
        </div>
      )}

    {/* Confirmation Dialog */}
    {showConfirmDialog && (
      <div className="confirmation-dialog">
        <p>Are you sure you want to deactivate your account?</p>
        <button onClick={handleDeactivateAccount} className="confirm-button">
          Confirm
        </button>
        <button onClick={() => setShowConfirmDialog(false)} className="cancel-button">
          Cancel
        </button>
      </div>
    )}
    </div>
  );
};

export default Bio;
