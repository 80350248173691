import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { confirmSignUp } from 'aws-amplify/auth';
import './ConfirmSignup.css'; // Import the external CSS

const ConfirmSignup = () => {
  const [confirmationCode, setConfirmationCode] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const email = location.state?.email || ''; // Retrieve email from previous signup page

  const handleConfirmSignup = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // Confirm the user's signup using the confirmation code
      await confirmSignUp({
        username: email,
        confirmationCode: confirmationCode,
      });
      navigate('/dashboard', { state: { email: email } });
    } catch (err) {
      console.error('Error during confirmation:', err);
      setError(err.message || 'Failed to confirm sign-up. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="confirm-signup-container">
      <div className="confirm-signup-form-container">
        <h2 className="confirm-signup-title">Confirm Sign Up</h2>
        <form onSubmit={handleConfirmSignup} className="confirm-signup-form">
          <div className="form-group">
            <label htmlFor="confirmationCode">Confirmation Code</label>
            <input
              type="text"
              id="confirmationCode"
              className="form-input"
              value={confirmationCode}
              onChange={(e) => setConfirmationCode(e.target.value)}
              required
              placeholder="Enter your confirmation code"
            />
          </div>
          {error && <p className="error-message">{error}</p>} {/* Display error message */}
          <button type="submit" className="confirm-signup-button" disabled={loading}>
            {loading ? 'Confirming...' : 'Confirm Sign Up'}
          </button>
        </form>
        <div className="confirm-signup-footer">
          <button onClick={() => navigate('/login')} className="footer-button">
            Back to Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmSignup;
