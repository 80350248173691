// SignOutButton.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './SignOutButton.css';
import { signOut } from 'aws-amplify/auth';

const SignOutButton = () => {
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut();
      localStorage.removeItem('userEmail');
      navigate('/goodbye');  // Redirect to the goodbye page
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  return (
    <button className="signout-button" onClick={handleSignOut}>
      Sign Out
    </button>
  );
};

export default SignOutButton;
